import React, { useEffect, useRef, useState } from "react";
import { Content } from "../../interfaces/message";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import moment from "moment";
import { localize } from "../../helpers/helpers";
import DeleteMessage from "../../core/modals/group/deleteMessage";
import { post } from "../../connections/fetch_wrapper";
import Lightbox from "yet-another-react-lightbox";
import Loading from "../../core/common/component/Loading";

const ChatList: React.FC<{
  messages: Content[];
  msgInfoIconClick: (contact: Content) => void;
  forwardInfoIconClick: (contact: Content) => void;
  replyInfoIconClick: (contact: Content) => void;
  loading: boolean;
}> = ({
  messages,
  msgInfoIconClick,
  forwardInfoIconClick,
  replyInfoIconClick,
  loading,
}) => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState<Content | null>(null);
  const [messageList, setMessageList] = useState<Content[]>([]);

  useEffect(() => {
    setMessageList(messages);
  }, [messages]);
  // Function to group messages by date
  const groupMessagesByDate = (messages: Content[]) => {
    const groupedMessages: { [date: string]: Content[] } = {};

    messages.forEach((message) => {
      const date = new Date().toLocaleDateString();
      console.log("date", date);

      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });

    return groupedMessages;
  };

  function deleteInfoIconClick(contact: Content) {
    setConfirmDelete(true);
    setDeleteMsg(contact);
  }

  function handleCloseDeleteConfirmation() {
    setConfirmDelete(false);
    setDeleteMsg(null);
  }

  async function submitDeleteConfirmation(val: "me" | "all") {
    const formData = new FormData();
    formData.append("type", val);
    const response = await post(`/api/messages/${deleteMsg?.id}`, formData);
    if (response.code == 200) {
      handleCloseDeleteConfirmation();
      onDeleted(deleteMsg!.id!);
    } else {
      console.log(response);
    }
  }

  function onDeleted(id: number) {
    const updateMessageList: Content[] = [...messageList]; // Create a copy of the conversations array
    const index = updateMessageList.findIndex((item) => item.id === id);
    if (index === -1) return;
    updateMessageList.splice(index, 1); // Remove 1 element at the found index
    setMessageList(updateMessageList);
  }

  async function favoriteIconClick(contact: Content) {
    const formData = new FormData();
    formData.append("type", contact.is_favorite == true ? "0" : "1");
    const response = await post(
      `/api/messages/${contact?.id}/favorite`,
      formData
    );
    if (response.code == 200) {
      favoriteUpdate(contact);
    } else {
      console.log(response);
    }
  }

  function favoriteUpdate(contact: Content) {
    const updateMessageList: Content[] = [...messageList]; // Create a copy of the conversations array
    const index = updateMessageList.findIndex((item) => item.id === contact.id);
    if (index === -1) return;
    updateMessageList[index].is_favorite =
      contact.is_favorite == true ? false : true; // Remove 1 element at the found index
    setMessageList(updateMessageList);
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="messages" ref={messagesRef}>
      {Object.entries(groupMessagesByDate(messageList)).map(
        ([date, messages]) => (
          <>
            <div className="chat-line" key={"msg-list-" + date}>
              <span className="chat-date">
                {moment(date, "DD/MM/YYYY").format("dddd, MMMM D")}
              </span>
            </div>
            {messages.map((message) => (
              <>
                {message.type == null ? (
                  <div
                    className="chat-line chat-line-disable"
                    key={"msg-list-type-" + date}
                  >
                    <span className="chat-date chat-date-disable">
                      {message.content}
                    </span>
                  </div>
                ) : message.type == "sent" ? (
                  <RightMessage
                    key={"msg-list-type-" + date}
                    id={message.id}
                    favoriteIconClick={() => {
                      favoriteIconClick(message);
                    }}
                    deleteInfoIconClick={() => {
                      deleteInfoIconClick(message);
                    }}
                    onClickReply={() => {
                      console.log();
                    }}
                    message={message}
                    msgInfoIconClick={() => {
                      msgInfoIconClick(message);
                    }}
                    forwardInfoIconClick={() => {
                      forwardInfoIconClick(message);
                    }}
                    replyInfoIconClick={() => {
                      replyInfoIconClick(message);
                    }}
                  />
                ) : (
                  <LeftMessage
                    key={"msg-list-type-" + date}
                    id={message.id}
                    favoriteIconClick={() => {
                      favoriteIconClick(message);
                    }}
                    deleteInfoIconClick={() => {
                      deleteInfoIconClick(message);
                    }}
                    onClickReply={() => {
                      console.log();
                    }}
                    message={message}
                    msgInfoIconClick={() => {
                      msgInfoIconClick(message);
                    }}
                    forwardInfoIconClick={() => {
                      forwardInfoIconClick(message);
                    }}
                    replyInfoIconClick={() => {
                      replyInfoIconClick(message);
                    }}
                  />
                )}
              </>
            ))}
          </>
        )
      )}
      <DeleteMessage
        show={confirmDelete}
        handleClose={handleCloseDeleteConfirmation}
        handleSubmit={submitDeleteConfirmation}
        meOnly={deleteMsg?.type == "sent" ? false : true}
      />
    </div>
  );
};

export default ChatList;

export const LeftMessage: React.FC<{
  message: Content;
  favoriteIconClick: () => void;
  deleteInfoIconClick: () => void;
  msgInfoIconClick: () => void;
  forwardInfoIconClick: () => void;
  replyInfoIconClick: () => void;
  id: number;
  onClickReply: () => void;
}> = ({
  message,
  msgInfoIconClick,
  forwardInfoIconClick,
  replyInfoIconClick,
  id,
  onClickReply,
  deleteInfoIconClick,
  favoriteIconClick,
}) => {
  const [imageView, setImageView] = useState<number | null>(null);

  return (
    <div className="chats" id={`message-${id}`}>
      <div className="chat-avatar">
        <ImageWithBaseURL
          src={
            message.img == "" || message.img == null
              ? `${base_path}assets/img/avatar/profile.png`
              : `${api_base_url}/${message.img}`
          }
          className="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div className="chat-content">
        <div className="chat-profile-name">
          <h6>
            {message.sender}
            <span>
              {message.sent_at == null
                ? ""
                : moment(localize(message.sent_at)).format("h:mm A")}
            </span>
            {message.is_favorite ? (
              <span className="check-star msg-star">
                <i className="bx bxs-star" />
              </span>
            ) : (
              <></>
            )}
          </h6>
          <ActionMessage
            message={message}
            msgInfoIconClick={msgInfoIconClick}
            forwardInfoIconClick={forwardInfoIconClick}
            rightMsg={false}
            replyInfoIconClick={replyInfoIconClick}
            deleteInfoIconClick={deleteInfoIconClick}
            favoriteIconClick={favoriteIconClick}
          />
        </div>
        <div className="message-content">
          {message.forward_msg_id != null && (
            <div className="forward-msg">
              <i className="bx bx-share me-1"></i>Forwarded
            </div>
          )}
          {message.reply_msg_id && (
            <div className="replay-msg mb-1" onClick={onClickReply}>
              {message.content}
              <p className="mb-0">
                {message.file_type == null ? (
                  <></>
                ) : message.file_type == "image" ? (
                  <>
                    <i className="feather-image ms-1 me-1"></i>Image
                  </>
                ) : (
                  <>
                    <i className="feather-file-text me-1"></i>File
                  </>
                )}
              </p>
            </div>
          )}
          {message.file_type == "file" && (
            <>
              {message.attachments.map((attachment) => (
                <div
                  className="file-download d-flex align-items-center mb-2"
                  key={"att_file_" + attachment.id}
                >
                  <div className="file-type d-flex align-items-center justify-content-center me-2">
                    <i className="bx bxs-file" />
                  </div>
                  <div className="file-details">
                    <span className="file-name">{attachment.name}</span>
                    <ul>
                      <li>{Math.round(attachment.size / 1024)} KB</li>
                      <li>
                        <Link
                          to={`${api_base_url}${attachment.file}`}
                          target="_blank"
                          download
                        >
                          Download
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </>
          )}
          {message.file_type == "image" && (
            <div className="download-col">
              <ul className="nav mb-0">
                <Lightbox
                  open={imageView == message.id}
                  close={() => setImageView(null)}
                  slides={message.attachments.map((attachment) => ({
                    src: api_base_url + attachment.file,
                  }))}
                />
                {message.attachments
                  .filter((value, index) => index <= 1)
                  .map((attachment) => (
                    <li key={"att_img_" + attachment.id}>
                      <div className="image-download-col">
                        <Link
                          onClick={() => setImageView(message.id)}
                          to="#"
                          data-fancybox="gallery"
                          className="fancybox"
                        >
                          <ImageWithBaseURL
                            src={`${api_base_url}${attachment.cover_image}`}
                            alt=""
                          />
                        </Link>
                      </div>
                    </li>
                  ))}
                {message.attachments.length == 3 && (
                  <li>
                    <div className="image-download-col">
                      <Link
                        onClick={() => setImageView(message.id)}
                        to="#"
                        data-fancybox="gallery"
                        className="fancybox"
                      >
                        <ImageWithBaseURL
                          src={`${api_base_url}${message.attachments[2].cover_image}`}
                          alt=""
                        />
                      </Link>
                    </div>
                  </li>
                )}
                {message.attachments.length > 3 && (
                  <li>
                    <div className="image-download-col image-not-download">
                      <Link
                        onClick={() => setImageView(message.id)}
                        to="#"
                        data-fancybox="gallery"
                        className="fancybox"
                      >
                        <ImageWithBaseURL
                          src={`${api_base_url}${message.attachments[2].cover_image}`}
                          alt=""
                        />
                        <span>{message.attachments.length - 2}+</span>
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
          {message.content}
          <div className="emoj-group">
            <ul>
              <li>
                <Link to="#" onClick={forwardInfoIconClick}>
                  <i className="bx bx-share" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RightMessage: React.FC<{
  message: Content;
  favoriteIconClick: () => void;
  deleteInfoIconClick: () => void;
  msgInfoIconClick: () => void;
  forwardInfoIconClick: () => void;
  replyInfoIconClick: () => void;
  id: number;
  onClickReply: () => void;
}> = ({
  message,
  msgInfoIconClick,
  forwardInfoIconClick,
  replyInfoIconClick,
  id,
  onClickReply,
  deleteInfoIconClick,
  favoriteIconClick,
}) => {
  const [imageView, setImageView] = useState<number | null>(null);

  return (
    <div className="chats chats-right" id={`message-${id}`}>
      <div className="chat-content">
        <div className="chat-profile-name text-end">
          <h6>
            {message.status == "WAIT" ? (
              <></>
            ) : (
              <i
                className={`bx ${message.is_seen == true ? "bx-check-double check" : message.is_deleverd == true ? "bx-check-double" : message.is_sent == true ? "bx-check" : ""}  me-1 `}
                // inactive-check
              />
            )}
            {message.sender}
            <span>
              {message.sent_at == null
                ? ""
                : moment(localize(message.sent_at)).format("h:mm A")}
            </span>
            {message.is_favorite ? (
              <span className="check-star msg-star">
                <i className="bx bxs-star" />
              </span>
            ) : (
              <></>
            )}
          </h6>
          <ActionMessage
            message={message}
            msgInfoIconClick={msgInfoIconClick}
            forwardInfoIconClick={forwardInfoIconClick}
            replyInfoIconClick={replyInfoIconClick}
            deleteInfoIconClick={deleteInfoIconClick}
            favoriteIconClick={favoriteIconClick}
            rightMsg={true}
          />
        </div>
        <div className="message-content fancy-msg-box">
          <div className="emoj-group wrap-emoji-group">
            <ul>
              <li>
                <Link to="#" onClick={forwardInfoIconClick}>
                  <i className="bx bx-share" />
                </Link>
              </li>
            </ul>
          </div>
          {message.forward_msg_id != null && (
            <div className="forward-msg">
              <i className="bx bx-share me-1"></i>Forwarded
            </div>
          )}
          {message.reply_msg_id && (
            <div className="replay-msg mb-1" onClick={onClickReply}>
              {message.content}
              <p className="mb-0">
                {message.file_type == null ? (
                  <></>
                ) : message.file_type == "image" ? (
                  <>
                    <i className="feather-image ms-1 me-1"></i>Image
                  </>
                ) : (
                  <>
                    <i className="feather-file-text me-1"></i>File
                  </>
                )}
              </p>
            </div>
          )}
          {message.file_type == "file" && (
            <>
              {message.attachments.map((attachment) => (
                <div
                  className="file-download d-flex align-items-center mb-2"
                  key={"att_file_" + attachment.id}
                >
                  <div className="file-type d-flex align-items-center justify-content-center me-2">
                    <i className="bx bxs-file" />
                  </div>
                  <div className="file-details">
                    <span className="file-name">{attachment.name}</span>
                    <ul>
                      <li>{Math.round(attachment.size / 1024)} KB</li>
                      <li>
                        <Link
                          to={`${api_base_url}${attachment.file}`}
                          target="_blank"
                          download
                        >
                          Download
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </>
          )}
          {message.file_type == "image" && (
            <div className="download-col">
              <Lightbox
                open={imageView == message.id}
                close={() => setImageView(null)}
                slides={message.attachments.map((attachment) => ({
                  src: api_base_url + attachment.file,
                }))}
              />
              <ul className="nav mb-0">
                {message.attachments
                  .filter((value, index) => index <= 1)
                  .map((attachment) => (
                    <li key={"att_img_" + attachment.id}>
                      <div className="image-download-col">
                        <Link
                          onClick={() => setImageView(message.id)}
                          to="#"
                          data-fancybox="gallery"
                          className="fancybox"
                        >
                          <ImageWithBaseURL
                            src={`${api_base_url}${attachment.cover_image}`}
                            alt=""
                          />
                        </Link>
                      </div>
                    </li>
                  ))}
                {message.attachments.length == 3 && (
                  <li>
                    <div className="image-download-col">
                      <Link
                        onClick={() => setImageView(message.id)}
                        to="#"
                        data-fancybox="gallery"
                        className="fancybox"
                      >
                        <ImageWithBaseURL
                          src={`${api_base_url}${message.attachments[2].cover_image}`}
                          alt=""
                        />
                      </Link>
                    </div>
                  </li>
                )}
                {message.attachments.length > 3 && (
                  <li>
                    <div className="image-download-col image-not-download">
                      <Link
                        onClick={() => setImageView(message.id)}
                        to="#"
                        data-fancybox="gallery"
                        className="fancybox"
                      >
                        <ImageWithBaseURL
                          src={`${api_base_url}${message.attachments[2].cover_image}`}
                          alt=""
                        />
                        <span>{message.attachments.length - 2}+</span>
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
          {message.content}

          {/* <div className="chat-voice-group">
                        <ul>
                          <li>
                            <Link to="#">
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icon/play-01.svg"
                                  alt="image"
                                />
                              </span>
                            </Link>
                          </li>
                          <li>
                            <ImageWithBasePath
                              src="assets/img/voice.svg"
                              alt="image"
                            />
                          </li>
                          <li>0:05</li>
                        </ul>
                      </div> */}
        </div>
      </div>
      <div className="chat-avatar">
        <ImageWithBaseURL
          src={
            message.img == "" || message.img == null
              ? `${base_path}assets/img/avatar/profile.png`
              : `${api_base_url}/${message.img}`
          }
          className="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
    </div>
  );
};

export const ActionMessage: React.FC<{
  message: Content;
  msgInfoIconClick: () => void;
  rightMsg: boolean;
  forwardInfoIconClick: () => void;
  replyInfoIconClick: () => void;
  deleteInfoIconClick: () => void;
  favoriteIconClick: () => void;
}> = ({
  message,
  msgInfoIconClick,
  rightMsg,
  forwardInfoIconClick,
  replyInfoIconClick,
  deleteInfoIconClick,
  favoriteIconClick,
}) => {
  return (
    <div className="chat-action-btns ms-2">
      <div className="chat-action-col">
        <Link className="#" to="#" data-bs-toggle="dropdown">
          <i className="bx bx-dots-horizontal-rounded" />
        </Link>
        <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
          {rightMsg && (
            <Link
              to="#"
              className="dropdown-item message-info-left"
              onClick={msgInfoIconClick}
            >
              <span>
                <i className="bx bx-info-circle" />
              </span>
              Message Info{" "}
            </Link>
          )}
          <Link
            to="#"
            className="dropdown-item reply-button"
            onClick={replyInfoIconClick}
          >
            <span>
              <i className="bx bx-share" />
            </span>
            Reply
          </Link>
          <Link to="#" className="dropdown-item" onClick={forwardInfoIconClick}>
            <span>
              <i className="bx bx-reply" />
            </span>
            Forward
          </Link>
          <Link
            to="#"
            className="dropdown-item click-star-one"
            onClick={favoriteIconClick}
          >
            <span>
              <i className="bx bx-star" />
            </span>
            <span className="star-msg-one">Star Message</span>
          </Link>
          <Link to="#" className="dropdown-item" onClick={deleteInfoIconClick}>
            <span>
              <i className="bx bx-trash" />
            </span>
            Delete
          </Link>
        </div>
      </div>
    </div>
  );
};
