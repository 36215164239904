import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../feature-module/router/all_routes";
const routes = all_routes;

export const ChatTitle = () => {
  return (
    <div className="left-chat-title all-chats d-flex justify-content-between align-items-center">
      <div className="select-group-chat">
        <div className="dropdown">
          <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown">
            All Chats
            <i className="bx bx-chevron-down" />
          </Link>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item" to={routes.allChat}>
                All Chat
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={routes.archiveChat}>
                Archive Chat
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={routes.pinnedChat}>
                Pinned Chat
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="add-section">
        <ul>
          <li>
            <Link to="#" className="user-chat-search-btn">
              <i className="bx bx-search" />
            </Link>
          </li>
          <li>
            <div className="chat-action-btns">
              <div className="chat-action-col">
                <Link
                  className="#"
                  to="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bx bx-dots-vertical-rounded" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link
                    to="#"
                    className="dropdown-item "
                    data-bs-toggle="modal"
                    data-bs-target="#new-chat"
                  >
                    <span>
                      <i className="bx bx-message-rounded-add" />
                    </span>
                    New Chat{" "}
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#new-group"
                  >
                    <span>
                      <i className="bx bx-user-circle" />
                    </span>
                    Create Group
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#invite-other"
                  >
                    <span>
                      <i className="bx bx-user-plus" />
                    </span>
                    Invite Others
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
        {/* Chat Search */}
        <div className="user-chat-search">
          <form>
            <span className="form-control-feedback">
              <i className="bx bx-search" />
            </span>
            <input
              type="text"
              name="chat-search"
              placeholder="Search"
              className="form-control"
            />
            <div className="user-close-btn-chat">
              <span className="material-icons">close</span>
            </div>
          </form>
        </div>
        {/* /Chat Search */}
      </div>
    </div>
  );
};

interface Props {
  openNewChat: () => void;
  openNew: () => void;
}

export const ChatTitle2: React.FC<Props> = ({ openNewChat, openNew }) => {
  return (
    <div className="left-chat-title all-chats d-flex justify-content-between align-items-center">
      <div className="select-group-chat">
        <div className="dropdown">
          <Link
            to="#"
            className="dropdown-toggle"
            // data-bs-toggle="dropdown"
          >
            All Chats
            {/* <i className="bx bx-chevron-down" /> */}
          </Link>
          {/* <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to={routes.allChat}>
                            All Chat
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={routes.archiveChat}
                          >
                            Archive Chat
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={routes.pinnedChat}>
                            Pinned Chat
                          </Link>
                        </li>
                      </ul> */}
        </div>
      </div>
      <div className="add-section">
        <ul>
          {/* <li>
            <Link to="#" className="user-chat-search-btn">
              <i className="bx bx-search" />
            </Link>
          </li> */}
          <li>
            <div className="chat-action-btns">
              <div className="chat-action-col">
                <Link
                  className="#"
                  to="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bx bx-dots-vertical-rounded" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link
                    to="#"
                    className="dropdown-item "
                    // data-bs-toggle="modal"
                    // data-bs-target="#new-chat"
                    onClick={openNew}
                  >
                    <span>
                      <i className="bx bx-message-rounded-add" />
                    </span>
                    New Chat{" "}
                  </Link>
                  <Link to="#" onClick={openNewChat} className="dropdown-item">
                    <span>
                      <i className="bx bx-user-circle" />
                    </span>
                    Create Group
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
        {/* Chat Search */}
        <div className="user-chat-search">
          <form>
            <span className="form-control-feedback">
              <i className="bx bx-search" />
            </span>
            <input
              type="text"
              name="chat-search"
              placeholder="Search"
              className="form-control"
            />
            <div className="user-close-btn-chat">
              <span className="material-icons">close</span>
            </div>
          </form>
        </div>
        {/* /Chat Search */}
      </div>
    </div>
  );
};
