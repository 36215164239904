import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Content, MsgResponse } from "../../interfaces/message";
import { get } from "../../connections/fetch_wrapper";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { base_path, api_base_url } from "../../environment";
import moment from "moment";
import Lightbox from "yet-another-react-lightbox";
import { localize } from "../../helpers/helpers";

interface Props {
  isShowRightSidebar: boolean;
  onCloseSideBar: () => void;
  conversationId?: number;
}

const StarredMessages: React.FC<Props> = ({ isShowRightSidebar, onCloseSideBar, conversationId }) => {
  const [content, setContent] = useState<Content[]>([]);
  const [imageView, setImageView] = useState<number|null>(null);

  useEffect(() => {
    if (conversationId != undefined && conversationId != null) {
      fetchMessages();
    }
  }, [conversationId]);

  async function fetchMessages() {
    const response = await get(`/api/conversations/${conversationId}/messages`);
    if (response.code == 200) {
      setContent(response.data.data)
    } else {
      console.log(response);
    }
  }


  return (
    <div
      className={`right-sidebar right_sidebar_profile right-side-contact ${isShowRightSidebar ? "show-right-sidebar" : "hide-right-sidebar"}`}
      id="middle2"
    >
      <div className="right-sidebar-wrap active">
        <div className="slimscroll">
          <div className="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
            <div className="fav-title mb-0">
              <h6>
                <Link to="#" className="remove-star-message d-flex" onClick={onCloseSideBar}>
                  <i className="bx bx-arrow-back me-2" />
                </Link>
                Starred Messages
              </h6>
            </div>
            <div className="star-drop">
              <Link
                className="btn btn-outline-light"
                to="#"
                data-bs-toggle="dropdown"
              >
                <i className="bx bx-dots-vertical-rounded" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="#" className="dropdown-item ">
                  <span>
                    <i className="feather-star" />
                  </span>
                  Unstar All{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="sidebar-body chat star-chat-group">
            <div className="chat-body">
              <div className="messages">
                {content.map((message) => (
                  <div className="chats" key={message.id}>
                    <div className="chat-content">
                      <div className="chat-profile-name">
                        <h6>
                          <span>{message.sent_at == null ? '' : moment(localize(message.sent_at)).format('h:mm A')}</span>
                        </h6>
                        <div className="chat-action-btns ms-2">
                          <div className="chat-action-col">
                            <Link
                              className="#"
                              to="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-horizontal-rounded" />
                            </Link>
                            <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                              <Link to="#" className="dropdown-item ">
                                <span>
                                  <i className="bx bx-info-circle" />
                                </span>
                                Message Info{" "}
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item reply-button"
                              >
                                <span>
                                  <i className="bx bx-share" />
                                </span>
                                Reply
                              </Link>
                              <Link to="#" className="dropdown-item">
                                <span>
                                  <i className="bx bx-smile" />
                                </span>
                                React
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#forward-message"
                              >
                                <span>
                                  <i className="bx bx-reply" />
                                </span>
                                Forward
                              </Link>
                              <Link to="#" className="dropdown-item">
                                <span>
                                  <i className="bx bx-star" />
                                </span>
                                Unstar Message
                              </Link>
                              <Link to="#" className="dropdown-item">
                                <span>
                                  <i className="bx bx-dislike" />
                                </span>
                                Report
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#delete-message"
                              >
                                <span>
                                  <i className="bx bx-trash" />
                                </span>
                                Delete
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="message-content mb-2">
                        {message.forward_msg_id != null && <div className="forward-msg">
                          <i className="bx bx-share me-1"></i>Forwarded
                        </div>}
                        {message.reply_msg_id && <div className="replay-msg mb-1">
                          {message.content}
                          <p className='mb-0'>{message.file_type == 'image' ?
                            <><i className="feather-image ms-1 me-1"></i>Image</> : <><i className="feather-file-text me-1"></i>File</>}</p>
                        </div>}
                        {message.file_type == 'file' &&
                          <>
                            {message.attachments.map((attachment) => (
                              <div className="file-download d-flex align-items-center mb-2" key={'att_file_' + attachment.id}>
                                <div className="file-type d-flex align-items-center justify-content-center me-2">
                                  <i className="bx bxs-file" />
                                </div>
                                <div className="file-details">
                                  <span className="file-name">{attachment.name}</span>
                                  <ul>
                                    <li>{Math.round(attachment.size / 1024)} KB</li>
                                    <li>
                                      <Link to={`${api_base_url}${attachment.file}`} target="_blank" download>Download</Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}

                          </>
                        }
                        {message.file_type == 'image' &&
                          <div className="download-col">
                            <ul className="nav mb-0">
                              <Lightbox
                                open={imageView == message.id}
                                close={() => setImageView(null)}
                                slides={message.attachments.map(attachment => ({ src: api_base_url + attachment.file }))}

                              />
                              {message.attachments.filter((value, index) => index <= 1).map((attachment) => (

                                <li key={'att_img_' + attachment.id}>
                                  <div className="image-download-col">
                                    <Link
                                      onClick={() => setImageView(message.id)}
                                      to='#'
                                      data-fancybox="gallery"
                                      className="fancybox"
                                    >
                                      <ImageWithBaseURL
                                        src={`${api_base_url}${attachment.cover_image}`}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </li>
                              ))}
                              {message.attachments.length == 3 &&
                                <li>
                                  <div className="image-download-col">
                                    <Link
                                      onClick={() => setImageView(message.id)}
                                      to='#'
                                      data-fancybox="gallery"
                                      className="fancybox"
                                    >
                                      <ImageWithBaseURL
                                        src={`${api_base_url}${message.attachments[2].cover_image}`}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </li>}
                              {message.attachments.length > 3 &&
                                <li>
                                  <div className="image-download-col image-not-download">
                                    <Link
                                      onClick={() => setImageView(message.id)}
                                      to='#'
                                      data-fancybox="gallery"
                                      className="fancybox"
                                    >
                                      <ImageWithBaseURL
                                        src={`${api_base_url}${message.attachments[2].cover_image}`}
                                        alt=""
                                      />
                                      <span>{message.attachments.length - 2}+</span>
                                    </Link>
                                  </div>
                                </li>}
                            </ul>
                          </div>
                        }
                        {message.content}
                      </div>
                      <div className="message-star">
                        <div className="avatar">
                          <ImageWithBasePath
                            src="assets/img/avatar/avatar-4.jpg"
                            className="rounded-circle"
                            alt="Icon"
                          />
                        </div>
                        <h6>
                          Alex Smith
                          <span className="ms-1">
                            <i className="fa-solid fa-star" />
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>))}
                <div className="chats">
                  <div className="chat-avatar">
                    <ImageWithBasePath
                      src="assets/img/avatar/avatar-2.jpg"
                      className="rounded-circle dreams_chat"
                      alt="image"
                    />
                  </div>
                  <div className="chat-content">
                    <div className="chat-profile-name">
                      <h6>
                        Mark Villiams<span>8:16 PM</span>
                      </h6>
                      <div className="chat-action-btns ms-2">
                        <div className="chat-action-col">
                          <Link
                            className="#"
                            to="#"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bx bx-dots-horizontal-rounded" />
                          </Link>
                          <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                            <Link to="#" className="dropdown-item ">
                              <span>
                                <i className="bx bx-info-circle" />
                              </span>
                              Message Info{" "}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item reply-button"
                            >
                              <span>
                                <i className="bx bx-share" />
                              </span>
                              Reply
                            </Link>
                            <Link to="#" className="dropdown-item">
                              <span>
                                <i className="bx bx-smile" />
                              </span>
                              React
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#forward-message"
                            >
                              <span>
                                <i className="bx bx-reply" />
                              </span>
                              Forward
                            </Link>
                            <Link to="#" className="dropdown-item">
                              <span>
                                <i className="bx bx-star" />
                              </span>
                              Unstar Message
                            </Link>
                            <Link to="#" className="dropdown-item">
                              <span>
                                <i className="bx bx-dislike" />
                              </span>
                              Report
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-message"
                            >
                              <span>
                                <i className="bx bx-trash" />
                              </span>
                              Delete
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="message-content mb-2 award-link">
                      <Link to="#">
                        https://www.youtube.com/watch?v=GCmL3mS0Psk
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/award.jpg"
                        alt="img"
                      />
                    </div>
                    <div className="message-star">
                      <div className="avatar">
                        <ImageWithBasePath
                          src="assets/img/avatar/avatar-4.jpg"
                          className="rounded-circle"
                          alt="Icon"
                        />
                      </div>
                      <h6>
                        Alex Smith
                        <span className="ms-1">
                          <i className="fa-solid fa-star" />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StarredMessages;