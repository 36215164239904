import ImageWithBasePath from "../img/ImageWithBasePath";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Conversation } from "../../../interfaces/message";
import { deleteMethod, post } from "../../../connections/fetch_wrapper";
import moment from "moment";

interface Props {
  conversation: Conversation | null;
  onUpdated: (id: number, type: "delete" | "mute" | "block") => void;
}

const HomeModals: React.FC<Props> = ({ conversation, onUpdated }) => {
  const [duration, setDuration] = useState<number | null>(null);

  async function deleteConversetion() {
    if (conversation == null) {
      return;
    }
    const response = await deleteMethod(`/api/conversation/${conversation.id}`);
    if (response.code == 200) {
      onUpdated(conversation.id, "delete");
    } else {
      console.log(response);
    }
  }

  function unMuteConversetion() {
    setDuration(null);
    muteConversetion();
  }

  async function muteConversetion() {
    if (conversation == null) {
      return;
    }

    const formData = new FormData();

    if (conversation.conversation_type == "GROUP") {
      formData.append("group_sid", conversation.gsid);
    } else {
      formData.append("to_user_sid", conversation.sid);
    }

    if (duration == null) {
      formData.append("date", "");
    } else if (duration == -1) {
      formData.append("date", "2200-01-01 00:00:00");
    } else {
      formData.append(
        "date",
        moment().add(duration, "minutes").utc().format("YYYY-MM-DD HH:mm:ss")
      );
    }
    const response = await post(`/api/contacts/mute`, formData);
    if (response.code == 200) {
      setDuration(null);
      onUpdated(conversation.id, "mute");
    } else {
      console.log(response);
    }
  }

  async function lockConversetion() {
    if (conversation == null) {
      return;
    }

    if (conversation.conversation_type == "GROUP") {
      return;
    }

    const formData = new FormData();

    const response = await post(
      `/api/contacts/${conversation.sid}/${!conversation.blocked_by_me ? "block" : "unblock"}`,
      formData
    );
    if (response.code == 200) {
      onUpdated(conversation.id, "block");
    } else {
      console.log(response);
    }
  }

  return (
    <div>
      {/* New Chat */}
      <div className="modal fade " id="new-chat">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Chat</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="user-block-group mb-4">
                  <div className="search_chat has-search">
                    <span className="fas fa-search form-control-feedback" />
                    <input
                      className="form-control chat_input"
                      id="search-contacted"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <h5>Contacts</h5>
                  <div className="sidebar sroll-side-view">
                    <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                      <div className="fav-title contact-title">
                        <h6>A</h6>
                      </div>
                    </div>
                    <ul className="user-list">
                      <li className="user-list-item">
                        <div className="list-user-blk">
                          <div className="avatar">
                            <ImageWithBasePath
                              src="assets/img/avatar/avatar-4.jpg"
                              className="rounded-circle"
                              alt="image"
                            />
                          </div>
                          <div className="users-list-body">
                            <div>
                              <h5>Alexander Manuel</h5>
                              <p>Active 4Min Ago</p>
                            </div>
                          </div>
                          <div className="notify-check parti-notify-radio">
                            <div className="form-check d-flex align-items-center justify-content-start ps-0">
                              <label className="group-type-radio">
                                <input type="radio" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="user-list-item">
                        <div className="list-user-blk">
                          <div className="avatar">
                            <ImageWithBasePath
                              src="assets/img/avatar/avatar-5.jpg"
                              className="rounded-circle"
                              alt="image"
                            />
                          </div>
                          <div className="users-list-body">
                            <div>
                              <h5>Annette Theriot</h5>
                              <p>Online</p>
                            </div>
                          </div>
                          <div className="notify-check parti-notify-radio">
                            <div className="form-check d-flex align-items-center justify-content-start ps-0">
                              <label className="group-type-radio">
                                <input type="radio" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                      <div className="fav-title contact-title">
                        <h6>B</h6>
                      </div>
                    </div>
                    <ul className="user-list">
                      <li className="user-list-item">
                        <div className="list-user-blk">
                          <div className="avatar">
                            <ImageWithBasePath
                              src="assets/img/avatar/avatar-10.jpg"
                              className="rounded-circle"
                              alt="image"
                            />
                          </div>
                          <div className="users-list-body">
                            <div>
                              <h5>Bacon Mark</h5>
                              <p>Active 8Min Ago</p>
                            </div>
                          </div>
                          <div className="notify-check parti-notify-radio">
                            <div className="form-check d-flex align-items-center justify-content-start ps-0">
                              <label className="group-type-radio">
                                <input type="radio" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="user-list-item">
                        <div className="list-user-blk">
                          <div className="avatar">
                            <ImageWithBasePath
                              src="assets/img/avatar/avatar-12.jpg"
                              className="rounded-circle"
                              alt="image"
                            />
                          </div>
                          <div className="users-list-body">
                            <div>
                              <h5>Bennett Gerard</h5>
                              <p>Last Seen Today at 12:35 AM</p>
                            </div>
                          </div>
                          <div className="notify-check parti-notify-radio">
                            <div className="form-check d-flex align-items-center justify-content-start ps-0">
                              <label className="group-type-radio">
                                <input type="radio" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mute-chat-btn">
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="feather-x me-1" />
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <ImageWithBasePath
                      src="assets/img/icon/send.svg"
                      className="me-1"
                      alt="image"
                    />
                    Chat
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /New Chat */}
      {/* New Chat */}

      {/* /New Chat */}
      {/* Invite */}
      <div className="modal fade " id="invite-other">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Invite Friends</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="user-profiles-group mb-4">
                  <div className="pass-login">
                    <label className="form-label">
                      Email Address or Phone Number
                    </label>
                    <input
                      type="password"
                      className="form-control pass-input"
                    />
                  </div>
                  <div className="pass-login">
                    <label className="form-label">Invitation Message </label>
                    <textarea className="form-control " defaultValue={""} />
                  </div>
                </div>
                <div className="mute-chat-btn">
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="feather-x me-1" />
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="bx bx-send me-1" />
                    Send Invitation
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Invite */}
      {/* Mute */}
      <div className="modal fade mute-notify" id="mute-notification">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Mute Notifications</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="notify-check">
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      30 Minutes
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 30}
                        onChange={() => setDuration(30)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      1 Hour
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 60}
                        onChange={() => setDuration(60)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      1 Day
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 1440}
                        onChange={() => setDuration(1440)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      1 Week
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 10080}
                        onChange={() => setDuration(10080)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      1 Month
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 43800}
                        onChange={() => setDuration(43800)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      1 Year
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === 525600}
                        onChange={() => setDuration(525600)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center justify-content-start ps-0">
                    <label className="group-type-radio">
                      Always
                      <input
                        type="radio"
                        name="radio"
                        checked={duration === -1}
                        onChange={() => setDuration(-1)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="mute-chat-btn">
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={muteConversetion}
                  >
                    Mute
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Mute */}
      {/* Deleting a Chat */}
      <div className="modal fade" id="change-chat">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Chat</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="block-user-blk text-center">
                <i className="bx bx-trash change-color " />
                <p>
                  Clearing or deleting entire chats will only remove messages
                  from this device and your devices on the newer versions of
                  DreamsChat.
                </p>
              </div>
              <div className="mute-chat-btn justify-content-center">
                <a
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </a>
                <a
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={deleteConversetion}
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Deleting a Chat */}
      {/* Unmute a Chat */}
      <div className="modal fade" id="unmute-notification">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Unmute Chat</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="block-user-blk text-center">
                <i className="bx bx-volume-full change-color" />
                <p>
                  Are you sure you want to unmute notifications for this
                  conversation?
                </p>
              </div>
              <div className="mute-chat-btn justify-content-center">
                <a
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </a>
                <a
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={unMuteConversetion}
                >
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Unmute a Chat */}
      {/* Block a Chat */}
      <div className="modal fade" id="block-chat">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {!conversation?.blocked_by_me ? "Block Chat" : "Unblock Chat"}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="block-user-blk text-center">
                <i
                  className={`bx bxs-${!conversation?.blocked_by_me ? "lock" : "lock-open"} change-color`}
                />
                <p>
                  Are you sure you want to{" "}
                  {!conversation?.blocked_by_me ? "block" : "unblock"} for this
                  conversation?
                </p>
              </div>
              <div className="mute-chat-btn justify-content-center">
                <a
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </a>
                <a
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={lockConversetion}
                >
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Block a Chat */}
    </div>
  );
};

export default HomeModals;
