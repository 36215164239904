import Swal from 'sweetalert2';

export const showAlert = (type: 'success' | 'error', message: string): void => {
  Swal.fire({
    title: type === 'success' ? 'Success!' : 'Error!',
    text: message,
    icon: type,
    confirmButtonText: 'OK',
  });
};
