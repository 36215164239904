import React, { useEffect, useState } from "react";
import { Conversation, ConversationReadCount } from "../../interfaces/message";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import TimeAgo from "react-timeago";
import { api_base_url, base_path } from "../../environment";
import { localize } from "../../helpers/helpers";

interface Props {
  conversations: Conversation[];
  conversationReadCount: ConversationReadCount[];
  gotoChat: (conversation: Conversation) => void;
  selectedConversation: (conversation: Conversation) => void;
  matkAsRead: (id: number) => Promise<void>
}

const ConversationsList: React.FC<Props> = ({
  conversations,
  gotoChat,
  selectedConversation,
  conversationReadCount,
  matkAsRead
}) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  return (
    <div className="sidebar-body chat-body" id="chatsidebar">
      {/* Left Chat Title */}
      {conversations.filter((conversation) => conversation.is_pinned == true)
        .length > 0 ?? (
        <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
          <div className="fav-title pin-chat">
            <h6>
              <i className="bx bx-pin me-1" />
              Pinned Chat
            </h6>
          </div>
        </div>
      )}
      {/* /Left Chat Title */}
      <ul className="user-list space-chat">
        {conversations
          .filter((conversation) => conversation.is_pinned == true)
          .map((filteredConversation) => (
            <li
              className="user-list-item chat-user-list"
              key={filteredConversation.id}
            >
              <Link to="#" className="status-active">
                <div className="avatar avatar-online">
                  <ImageWithBasePath
                    src="assets/img/avatar/avatar-2.jpg"
                    className="rounded-circle"
                    alt="image"
                  />
                </div>
                <div className="users-list-body">
                  <div>
                    <h5>{filteredConversation.name}</h5>
                    <p>{filteredConversation.content}</p>
                  </div>
                  <div className="last-chat-time">
                    <small className="text-muted">10:20 PM</small>
                    <div className="chat-pin">
                      <i className="bx bx-pin me-2" />
                      <i className="bx bx-check-double" />
                    </div>
                  </div>
                </div>
              </Link>
              <div className="chat-hover ms-1">
                <div className="chat-action-col">
                  <span className="d-flex" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded" />
                  </span>
                  <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                    <span className="dropdown-item ">
                      <span>
                        <i className="bx bx-archive-in" />
                      </span>
                      Archive Chat{" "}
                    </span>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#mute-notification"
                    >
                      <span>
                        <i className="bx bx-volume-mute" />
                      </span>
                      {!filteredConversation.mute
                        ? "Mute Notification"
                        : "Unmute Notification"}
                    </span>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#change-chat"
                    >
                      <span>
                        <i className="bx bx-log-out" />
                      </span>
                      Delete Chat
                    </span>
                    <span className="dropdown-item">
                      <span>
                        <i className="bx bx-pin" />
                      </span>
                      Unpin Chat
                    </span>
                    <span className="dropdown-item">
                      <span>
                        <i className="bx bx-check-square" />
                      </span>
                      Mark as Unread
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
      {/* Left Chat Title */}
      <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
        <div className="fav-title pin-chat">
          <h6>
            <i className="bx bx-message-square-dots me-1" />
            Recent Chat
          </h6>
        </div>
      </div>
      {/* /Left Chat Title */}
      <ul className="user-list">
        {conversations
          .filter((conversation) => conversation.is_pinned == false)
          .map((filteredConversation) => (
            <li
              className="user-list-item chat-user-list"
              key={filteredConversation.id}
            >
              <Link
                to="#"
                onClick={() => {
                  gotoChat(filteredConversation);
                  setActiveId(filteredConversation.id);
                }}
                className={
                  activeId == filteredConversation.id ? "status-active" : ""
                }
              >
                <div
                  className={`avatar ${filteredConversation.is_now_online ? "avatar-online" : ""}`}
                >
                  <ImageWithBaseURL
                    src={
                      filteredConversation.img == "" ||
                      filteredConversation.img == null
                        ? `${base_path}assets/img/avatar/${filteredConversation.conversation_type == "GROUP" ? "group.png" : "profile.png"}`
                        : `${api_base_url}/${filteredConversation.img}`
                    }
                    className="rounded-circle"
                    alt="image"
                  />
                </div>
                <div className="users-list-body">
                  <div>
                    <h5>{filteredConversation.name}</h5>
                    <p>
                      {filteredConversation.type == "received" &&
                      filteredConversation.conversation_type == "GROUP"
                        ? filteredConversation.sender + " :  "
                        : ""}
                      {filteredConversation.file_type == null ? (
                        filteredConversation.content
                      ) : filteredConversation.file_type == "image" ? (
                        <>
                          <i className="feather-image ms-1 me-1"></i>Image
                        </>
                      ) : (
                        <>
                          <i className="feather-file-text me-1"></i>File
                        </>
                      )}
                    </p>
                  </div>
                  <div className="last-chat-time">
                    <small className="text-muted">
                      {filteredConversation.sent_at == null ? (
                        <></>
                      ) : (
                        <TimeAgo
                          date={localize(filteredConversation.sent_at)}
                        />
                      )}
                    </small>
                    <div className="chat-pin">
                      {(filteredConversation.content === null &&
                        filteredConversation.file_type === null) ||
                      filteredConversation.sender == null ? (
                        <></>
                      ) : (
                        <i
                          className={`bx ${filteredConversation.type == "received" ? "" : filteredConversation.is_seen == true ? "bx-check-double check" : filteredConversation.is_deleverd == true ? "bx-check-double" : filteredConversation.is_sent == true ? "bx-check" : ""}`}
                        />
                      )}
                      {conversationReadCount.find(
                        (item) =>
                          item.conversation_id === filteredConversation.id
                      ) ? (
                        <span className="count-message">
                          {
                            conversationReadCount.find(
                              (item) =>
                                item.conversation_id === filteredConversation.id
                            )?.unread_msg
                          }
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
              <div
                className="chat-hover ms-1"
                onClick={() => {
                  selectedConversation(filteredConversation);
                }}
              >
                <div className="chat-action-col">
                  <span className="d-flex" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded" />
                  </span>
                  <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target={`#${!filteredConversation.mute ? "mute-notification" : "unmute-notification"}`}
                    >
                      <span>
                        <i
                          className={`bx bx-volume-${!filteredConversation.mute ? "mute" : "full"}`}
                        />
                      </span>
                      {!filteredConversation.mute
                        ? "Mute Notification"
                        : "Unmute Notification"}
                    </span>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#change-chat"
                    >
                      <span>
                        <i className="bx bx-trash" />
                      </span>
                      Delete Chat
                    </span>
                    {/* <span className="dropdown-item">
              <span>
                <i className="bx bx-pin" />
              </span>
              Pin Chat
            </span> */}
                    <span className="dropdown-item" 
                    onClick={()=>matkAsRead(filteredConversation.user_id)}
                    >
                      <span>
                        <i className="bx bx-check-square" />
                      </span>
                      Mark as Read
                    </span>
                    {filteredConversation.conversation_type == "INDIVIDUAL" && (
                      <span
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#block-chat"
                      >
                        <span>
                          <i className="bx bx-block" />
                        </span>
                        {filteredConversation.blocked_by_me
                          ? "Unblock"
                          : "Block"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ConversationsList;
