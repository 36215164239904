import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";
import {
  deleteMethod,
  get,
  post,
  postFormData,
} from "../../connections/fetch_wrapper";
import {
  Conversation,
  GroupInfoResponse,
  GroupMember,
  userInfoResponse,
} from "../../interfaces/message";
import Scrollbars from "react-custom-scrollbars-2";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { getUser } from "../../helpers/helpers";
import { text } from "stream/consumers";
import { showAlert } from "../../core/common/alertUtil";
const routes = all_routes;

interface Props {
  key: number;
  isShowRightSidebar: boolean;
  onCloseSideBar: () => void;
  onshowStarredMsg: (id: number) => void;
  toUserSid?: string;
  groupSid?: string;
  setSelectedConversation?: React.Dispatch<
    React.SetStateAction<Conversation | null>
  >;
  setNewKey: React.Dispatch<React.SetStateAction<number>>;
  setIsInGroup: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConversationInfo: React.FC<Props> = ({
  isShowRightSidebar,
  toUserSid,
  groupSid,
  onCloseSideBar,
  onshowStarredMsg,
  setSelectedConversation,
  key,
  setNewKey,
  setIsInGroup,
}) => {
  const [reportModal, setReportModal] = useState(false);
  const [reportText, setReportText] = useState("");
  const [reportParams, setReportParams] = useState({
    type: "",
    id: NaN,
  });

  const callReportModal = (type: string, id: number) => {
    setReportParams({
      type,
      id,
    });
    setReportModal(true);
  };
  return (
    <>
      <div
        className={`right-sidebar right_sidebar_profile right-side-contact ${isShowRightSidebar ? "show-right-sidebar" : "hide-right-sidebar"}`}
        id="middle1"
      >
        <div className="right-sidebar-wrap active">
          <Scrollbars>
            <div className="slimscroll">
              <div className="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
                <div className="fav-title mb-0">
                  {toUserSid ? (
                    <h6> Contact Info</h6>
                  ) : groupSid ? (
                    <h6> Group Info</h6>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="contact-close_call text-end">
                  <Link
                    to="#"
                    className="close_profile close_profile4"
                    onClick={onCloseSideBar}
                  >
                    <span className="material-icons">close</span>
                  </Link>
                </div>
              </div>
              {toUserSid ? (
                <ContactInfo
                  callReportModal={callReportModal}
                  key={key}
                  toUserSid={toUserSid}
                  onshowStarredMsg={onshowStarredMsg}
                  setSelectedConversation={setSelectedConversation}
                />
              ) : groupSid ? (
                <GroupInfo
                  setIsInGroup={setIsInGroup}
                  callReportModal={callReportModal}
                  key={key}
                  setNewKey={setNewKey}
                  toGroupSid={groupSid}
                  onshowStarredMsg={onshowStarredMsg}
                  setSelectedConversation={setSelectedConversation}
                />
              ) : (
                <></>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
      <Modal
        show={reportModal}
        onHide={() => {
          setReportModal(false);
        }}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable"
      >
        <div className="modal-content delete-model-head">
          <div className="modal-header">
            <h5 className="modal-title">Report</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setReportModal(false);
              }}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="modal-body">
            <p>This report will be sent to the admin.</p>
            <textarea
              className="form-control"
              rows={4}
              placeholder="Type your report here..."
              value={reportText}
              onChange={(e) => setReportText(e.target.value)}
            ></textarea>
            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-secondary me-2"
                onClick={() => setReportModal(false)}
              >
                <i className="feather-x me-1" /> Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={async () => {
                  const res = await post("/api/report", {
                    [reportParams.type === "group" ? "group_id" : "to_user_id"]:
                      reportParams.id,
                    text: reportText,
                  });
                  if (res.code == 200) {
                    setReportText("");
                    setReportModal(false);
                  } else {
                    // showAlert("error", "Fail to report");
                  }
                }}
                disabled={!reportText}
              >
                <i className="bx bx-send me-1" /> Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConversationInfo;

export const ContactInfo: React.FC<{
  toUserSid: string;
  onshowStarredMsg: (id: number) => void;
  setSelectedConversation?: React.Dispatch<
    React.SetStateAction<Conversation | null>
  >;
  key: number;
  callReportModal: (type: string, id: number) => void;
}> = ({
  toUserSid,
  onshowStarredMsg,
  setSelectedConversation,
  key,
  callReportModal,
}) => {
  const [data, setData] = useState<userInfoResponse | null>(null);

  useEffect(() => {
    if (toUserSid != null) {
      fetchChatData();
    }
  }, [toUserSid, key]);

  async function fetchChatData() {
    const response = await get(`/api/users/${toUserSid}`);
    if (response.code == 200) {
      console.log(response.data);
      setData(response.data);
    } else {
      console.log(response);
    }
  }

  const fetchUserData = async () => {
    try {
      const response = await get(
        `/api/conversation/${toUserSid}/find-by-to-user`
      );
      if (response.code === 200) {
        console.log(response.data);
        setSelectedConversation &&
          setSelectedConversation({
            ...response.data?.conversation,
            conversation_type: response.data?.conversation?.type,
            blocked_by_me: response.data?.conversation?.is_blocked_me,
          });
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  return (
    <>
      <div className="sidebar-body">
        <div className="mt-0 right_sidebar_logo">
          <div className="text-center right-sidebar-profile ">
            <figure className="avatar avatar-xl mb-3">
              <ImageWithBaseURL
                src={
                  data?.contact.img == "" || data?.contact.img == null
                    ? `${base_path}assets/img/avatar/profile.png`
                    : `${api_base_url}/${data?.contact.img}`
                }
                className="rounded-circle"
                alt="image"
              />
            </figure>
            <h5 className="profile-name">{data?.contact.name}</h5>
            <div className="last-seen-profile">
              <span>{data?.contact.last_online_at}</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="right-sidebar-head share-media">
        <div className="share-media-blk">
          <h5>Shared Media</h5>
          <Link to="#">View All</Link>
        </div>
        <div className="about-media-tabs">
          <nav>
            <div className="nav nav-tabs " id="nav-tab">
              <Link
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                to="#chat-image"
              >
                Media
              </Link>
              <Link
                className="nav-item nav-link"
                id="nav-profile-tab2"
                data-bs-toggle="tab"
                to="#media"
              >
                File
              </Link>
              <Link
                className="nav-item nav-link"
                id="nav-profile-tab3"
                data-bs-toggle="tab"
                to="#link"
              >
                Link
              </Link>
            </div>
          </nav>
          <div className="tab-content pt-0" id="nav-tabContent">
            <div className="tab-pane fade show active" id="chat-image">
              <ul className="nav share-media-img mb-0">
              {data?.images == null ? <></>: data?.images.map(image => (
                <li key={'common-image-'+image.id}>
                  <Link
                    to="assets/img/media/media-big-01.jpg"
                    data-fancybox="gallery"
                    className="fancybox"
                  >
                    <ImageWithBaseURL src={image.cover_image == '' || image.cover_image == null ? `${base_path}assets/img/avatar/profile.jpg`:`${api_base_url}${image.cover_image}`} alt="image" />
                  </Link>
                </li>
              ))}
                <li className="blur-media">
                  <Link
                    to="assets/img/media/media-02.jpg"
                    data-fancybox="gallery"
                    className="fancybox"
                  >
                    <ImageWithBasePath
                      src="assets/img/media/media-02.jpg"
                      alt=""
                    />
                  </Link>
                  <span>+10</span>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="media">
            {data?.files == null ? <></>: data?.files.map(file => (
              <div className="media-file" key={`common-file-${file.id}`}>
                <div className="media-doc-blk">
                  <span>
                    <i className="bx bxs-file-doc" />
                  </span>
                  <div className="document-detail">
                    <h6>{file.name}</h6>
                    <ul>
                      <li>12 Mar 2023</li>
                      <li>{Math.round(file.size / 1024)} KB</li>
                    </ul>
                  </div>
                </div>
                <div className="media-download">
                <Link to={`${api_base_url}/${file.attachment}`} target="_blank" download><i className="bx bx-download" /></Link>
                </div>
              </div>
            ))}
              <div className="media-file">
                <div className="media-doc-blk">
                  <span>
                    <i className="bx bxs-file-pdf" />
                  </span>
                  <div className="document-detail">
                    <h6>Design Guideless.pdf</h6>
                    <ul>
                      <li>12 Mar 2023</li>
                      <li>246.3 KB</li>
                    </ul>
                  </div>
                </div>
                <div className="media-download">
                  <Link to="#">
                    <i className="bx bx-download" />
                  </Link>
                </div>
              </div>
              <div className="media-file">
                <div className="media-doc-blk">
                  <span>
                    <i className="bx bxs-file" />
                  </span>
                  <div className="document-detail">
                    <h6>sample site.txt</h6>
                    <ul>
                      <li>12 Mar 2023</li>
                      <li>246.3 KB</li>
                    </ul>
                  </div>
                </div>
                <div className="media-download">
                  <Link to="#">
                    <i className="bx bx-download" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="link">
              <div className="media-link-grp">
                <div className="link-img">
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/media-link-01.jpg"
                      alt="Img"
                    />
                  </Link>
                </div>
                <div className="media-link-detail">
                  <h6>
                    <Link to="#">
                      Digital Marketing Guide
                    </Link>
                  </h6>
                  <span>
                    <Link to="#">
                      https://elements.envato.com/all-items/blog
                    </Link>
                  </span>
                </div>
              </div>
              <div className="media-link-grp mb-0">
                <div className="link-img">
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/media-link-02.jpg"
                      alt="Img"
                    />
                  </Link>
                </div>
                <div className="media-link-detail">
                  <h6>
                    <Link to="#">Blog Post</Link>
                  </h6>
                  <span>
                    <Link to="#">
                      https://elements.envato.com/blog-post-TXQ5FB8
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {data?.commonGroups != undefined && data?.commonGroups.length > 0 ? (
        <div className="group-comman-theme">
          <h6>{data?.commonGroups.length} Group in Common</h6>
          <ul>
            {data?.commonGroups.map((group) => (
              <li key={"common-group-" + group.id}>
                <div className="avatar">
                  <ImageWithBaseURL
                    src={
                      group.img == "" || group.img == null
                        ? `${base_path}assets/img/avatar/group.png`
                        : `${api_base_url}/${group.img}`
                    }
                    className="rounded-circle"
                    alt="image"
                  />
                </div>
                <div className="theme-content">
                  <h6>{group.title}</h6>
                  <p>{group.members_count} members</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
      <div className="chat-message-grp">
        <ul>
          <li>
            <Link
              to="#"
              className="star-message-left"
              onClick={() => {
                if (data?.contact.conversation_id != undefined) {
                  onshowStarredMsg(data?.contact.conversation_id);
                }
              }}
            >
              <div className="stared-group">
                <span className="star-message">
                  {" "}
                  <i className="bx bxs-star" />
                </span>
                <h6>Starred Messages</h6>
              </div>
              <div className="count-group">
                <span>
                  {data?.contact.stared == 0 ? "" : data?.contact.stared}
                </span>
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target={
                data?.contact.mute == true
                  ? "#unmute-notification"
                  : "#mute-notification"
              }
              onClick={async (e) => {
                e.preventDefault();
                await fetchUserData();
              }}
            >
              <div className="stared-group">
                <span className="mute-message">
                  {" "}
                  <i className="bx bxs-microphone-off" />
                </span>
                <h6>
                  {data?.contact.mute == true ? "Unmute" : "Mute"} Notifications
                </h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#block-chat"
              onClick={async (e) => {
                e.preventDefault();
                await fetchUserData();
              }}
            >
              <div className="stared-group">
                <span className="block-message">
                  {" "}
                  <i className="bx bx-block" />
                </span>
                <h6>
                  {data?.contact.is_blocked == true ? "Unblock" : "Block"} User
                </h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              data-bs-toggle=""
              data-bs-target=""
              onClick={() =>
                data &&
                data?.contact.id &&
                callReportModal("contact", data?.contact.id)
              }
            >
              <div className="stared-group">
                <span className="report-message">
                  {" "}
                  <i className="bx bx-dislike" />
                </span>
                <h6>Report User</h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={async (e) => {
                e.preventDefault();
                await fetchUserData();
              }}
              data-bs-toggle="modal"
              data-bs-target="#change-chat"
            >
              <div className="stared-group">
                <span className="delete-message">
                  {" "}
                  <i className="bx bx-trash" />
                </span>
                <h6>Delete Chat</h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export const GroupInfo: React.FC<{
  toGroupSid: string;
  onshowStarredMsg: (id: number) => void;
  setSelectedConversation?: React.Dispatch<
    React.SetStateAction<Conversation | null>
  >;
  key: number;
  setNewKey: React.Dispatch<React.SetStateAction<number>>;
  callReportModal: (type: string, id: number) => void;
  setIsInGroup: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  toGroupSid,
  onshowStarredMsg,
  setSelectedConversation,
  key,
  setNewKey,
  callReportModal,
  setIsInGroup,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<GroupInfoResponse | null>(null);
  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false,
  });
  const [temp, setTemp] = useState({
    title: data?.group_info.title,
    des: data?.group_info.about,
  });
  const [groupSettingModal, setGroupSettingModal] = useState(false);
  const [exitGroupModal, setExitGroupModal] = useState(false);

  useEffect(() => {
    if (toGroupSid != null) {
      fetchChatData();
    }
  }, [toGroupSid, key]);

  async function fetchChatData() {
    setIsInGroup(false);
    const response = await get(`/api/group/${toGroupSid}`);
    if (response.code == 200) {
      console.log(response.data);
      setData(response.data);
      const isUserInGroup = response.data.member_info.some(
        (member: GroupMember) => member.sid === getUser()?.code
      );
  
      setIsInGroup(isUserInGroup);
    } else {
      console.log(response);
    }
  }

  const fetchUserData = async () => {
    try {
      const response = await get(
        `/api/conversation/${toGroupSid}/find-by-group`
      );
      if (response.code === 200) {
        console.log(response.data);
        setSelectedConversation &&
          // setSelectedConversation({
          //   ...response.data?.conversation_info,
          //   conversation_type: response.data?.conversation_info?.type,
          // });
          setSelectedConversation({
            ...response.data?.conversation_info,
            conversation_type: response.data?.conversation_info?.type,
            gsid: response.data?.group_info?.sid,
          });
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  return (
    <>
      <div className="sidebar-body">
        <div className="mt-0 right_sidebar_logo">
          <div className="text-center right-sidebar-profile">
            <figure className="avatar avatar-xl mb-3">
              <ImageWithBasePath
                src={
                  `${api_base_url}/${data?.group_info?.img}` ||
                  "assets/img/avatar/avatar-2.jpg"
                }
                className="rounded-circle"
                alt="image"
                noBaseUrl
              />
              <input
                type="file"
                id="avatar_upload"
                onChange={async (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  const file = event.target.files?.[0];
                  if (file) {
                    const formData = new FormData();
                    formData.append("image", file);
                    const res = await postFormData(
                      `/api/groups/${data?.group_info.id}/update/image`,
                      formData
                    );

                    if (res.code === 200) {
                      fetchChatData();
                      setNewKey(Math.random());
                    } else {
                      console.error("Error updating group title");
                    }
                  }
                }}
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <span
                className="avatar-edit"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                <ImageWithBasePath
                  src="assets/img/icon/camera.svg"
                  alt="Image"
                  noBaseUrl={true}
                />
              </span>
            </figure>
            <h5 className="profile-name">
              <div
                className={`editable-container ${isEditing.title ? "editing" : ""}`}
              >
                {!isEditing.title ? (
                  <>
                    {data?.group_info.title}{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTemp({ ...temp, title: data?.group_info.title });
                        setIsEditing({ ...isEditing, title: true });
                      }}
                      className="edit-icon"
                    >
                      <i className="bx bx-pencil ms-1" />
                    </span>
                  </>
                ) : (
                  <div className="d-flex edit-input-container">
                    <input
                      type="text"
                      className="form-control"
                      value={temp?.title}
                      onChange={(e) =>
                        setTemp({ ...temp, title: e.target.value })
                      }
                    />
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await post(
                          `/api/groups/${data?.group_info.id}/update/title`,
                          {
                            title: temp.title,
                          }
                        );
                        if (res.code == 200) {
                          fetchChatData();
                          setIsEditing({ ...isEditing, title: false });
                          setNewKey(Math.random());
                        } else {
                          console.error("Error updating group title");
                        }
                      }}
                      className="update-icon"
                    >
                      <i className="bx bx-check ms-1" />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsEditing({ ...isEditing, title: false });
                      }}
                      className="cancel-icon"
                    >
                      <i className="bx bx-x ms-2" />
                    </span>
                  </div>
                )}
              </div>
            </h5>
            <div className="last-seen-profile">
              <span>
                Group ·&nbsp;{data?.group_info.members_count} participants
              </span>
            </div>
          </div>
          <div className="group-description">
            <div
              className={`editable-container ${isEditing.description ? "editing" : ""}`}
            >
              <div className="description-sub">
                <h5>
                  Group Description
                  {!isEditing.description && (
                    <span style={{ cursor: "pointer" }}>
                      <i
                        className="bx bx-pencil ms-1"
                        onClick={() => {
                          setTemp({
                            ...temp,
                            des: data?.group_info.about,
                          });
                          setIsEditing({ ...isEditing, description: true });
                        }}
                      />
                    </span>
                  )}
                </h5>
                {isEditing.description ? (
                  <div className="d-flex flex-column edit-input-container">
                    <textarea
                      className="form-control mb-2"
                      value={temp?.des || ""}
                      onChange={(e) =>
                        setTemp({ ...temp, des: e.target.value })
                      }
                    />
                    <div className="d-flex justify-content-end">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await post(
                            `/api/groups/${data?.group_info.id}/update/about`,
                            {
                              about: temp.des,
                            }
                          );
                          if (res.code == 200) {
                            fetchChatData();
                            setIsEditing({
                              ...isEditing,
                              description: false,
                            });
                            // setNewKey(Math.random());
                          } else {
                            console.error("Error updating group description");
                          }
                        }}
                        className="update-icon"
                      >
                        <i className="bx bx-check ms-1" />
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsEditing({ ...isEditing, description: false });
                        }}
                        className="cancel-icon"
                      >
                        <i className="bx bx-x ms-2" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <p>{data?.group_info.about}</p>
                )}
                <p className="description-date">
                  Group created by {data?.group_info.created_by},
                  {data?.group_info.created_at
                    ? " on " +
                      moment(data?.group_info.created_at).format(
                        "D MMM YYYY [at] h:mm a"
                      )
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="right-sidebar-head share-media">
        <div className="share-media-blk">
          <h5>Shared Media</h5>
          <Link to="#">View All</Link>
        </div>
        <div className="about-media-tabs">
          <nav>
            <div className="nav nav-tabs " id="nav-tab">
              <Link
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                to="#info"
              >
                Photos
              </Link>
              <Link
                className="nav-item nav-link"
                id="nav-profile-tab1"
                data-bs-toggle="tab"
                to="#Participants"
              >
                Videos
              </Link>
              <Link
                className="nav-item nav-link"
                id="nav-profile-tab2"
                data-bs-toggle="tab"
                to="#media"
              >
                File
              </Link>
              <Link
                className="nav-item nav-link"
                id="nav-profile-tab3"
                data-bs-toggle="tab"
                to="#link"
              >
                Link
              </Link>
            </div>
          </nav>
          <div className="tab-content pt-0" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="info">
                        <ul className="nav share-media-img mb-0">
                        <Lightbox
                            open={open2}
                            close={() => setOpen2(false)}
                            slides={[
                              { src: "assets/img/media/media-big-01.jpg" },
                              { src: "assets/img/media/media-big-02.jpg" },
                              { src: "assets/img/media/media-big-03.jpg" },
                              { src: "assets/img/media/media-big-04.jpg" },
                              { src: "assets/img/media/media-big-05.jpg" },
                              { src: "assets/img/media/media-02.jpg" },
                            ]}
                          />
                          <li>
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-big-01.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-01.jpg"
                                alt=""
                              />
                            </Link>
                          </li>
                          <li>
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-big-02.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-02.jpg"
                                alt=""
                              />
                            </Link>
                          </li>
                          <li>
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-big-03.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-03.jpg"
                                alt=""
                              />
                            </Link>
                          </li>
                          <li>
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-big-04.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-04.jpg"
                                alt=""
                              />
                            </Link>
                          </li>
                          <li>
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-05.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-05.jpg"
                                alt=""
                              />
                            </Link>
                          </li>
                          <li className="blur-media">
                            <Link onClick={() => setOpen2(true)}
                              href="assets/img/media/media-02.jpg"
                              data-fancybox="gallery"
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-02.jpg"
                                alt=""
                              />
                            </Link>
                            <span>+10</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="Participants">
                        <ul className="nav share-media-img mb-0">
                          <li>
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-01.jpg"
                                alt="img"
                              />
                              <span>
                                <i className="bx bx-play-circle" />
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-02.jpg"
                                alt="img"
                              />
                              <span>
                                <i className="bx bx-play-circle" />
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-03.jpg"
                                alt="img"
                              />
                              <span>
                                <i className="bx bx-play-circle" />
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-04.jpg"
                                alt="img"
                              />
                              <span>
                                <i className="bx bx-play-circle" />
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-05.jpg"
                                alt="img"
                              />
                              <span>
                                <i className="bx bx-play-circle" />
                              </span>
                            </Link>
                          </li>
                          <li className="blur-media">
                            <Link
                              to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                              data-fancybox=""
                              className="fancybox"
                            >
                              <ImageWithBasePath
                                src="assets/img/media/media-02.jpg"
                                alt="img"
                              />
                            </Link>
                            <span>+10</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="media">
                        <div className="media-file">
                          <div className="media-doc-blk">
                            <span>
                              <i className="bx bxs-file-doc" />
                            </span>
                            <div className="document-detail">
                              <h6>Landing_page_V1.doc</h6>
                              <ul>
                                <li>12 Mar 2023</li>
                                <li>246.3 KB</li>
                              </ul>
                            </div>
                          </div>
                          <div className="media-download">
                            <Link to="#">
                              <i className="bx bx-download" />
                            </Link>
                          </div>
                        </div>
                        <div className="media-file">
                          <div className="media-doc-blk">
                            <span>
                              <i className="bx bxs-file-pdf" />
                            </span>
                            <div className="document-detail">
                              <h6>Design Guideless.pdf</h6>
                              <ul>
                                <li>12 Mar 2023</li>
                                <li>246.3 KB</li>
                              </ul>
                            </div>
                          </div>
                          <div className="media-download">
                            <Link to="#">
                              <i className="bx bx-download" />
                            </Link>
                          </div>
                        </div>
                        <div className="media-file">
                          <div className="media-doc-blk">
                            <span>
                              <i className="bx bxs-file" />
                            </span>
                            <div className="document-detail">
                              <h6>sample site.txt</h6>
                              <ul>
                                <li>12 Mar 2023</li>
                                <li>246.3 KB</li>
                              </ul>
                            </div>
                          </div>
                          <div className="media-download">
                            <Link to="#">
                              <i className="bx bx-download" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="link">
                        <div className="media-link-grp">
                          <div className="link-img">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/media-link-01.jpg"
                                alt="Img"
                              />
                            </Link>
                          </div>
                          <div className="media-link-detail">
                            <h6>
                              <Link to="#">Digital Marketing Guide</Link>
                            </h6>
                            <span>
                              <Link to="#">
                                https://elements.envato.com/all-items/blog
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className="media-link-grp mb-0">
                          <div className="link-img">
                            <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/media-link-02.jpg"
                                alt="Img"
                              />
                            </Link>
                          </div>
                          <div className="media-link-detail">
                            <h6>
                              <Link to="#">Blog Post</Link>
                            </h6>
                            <span>
                              <Link to="#">
                                https://elements.envato.com/blog-post-TXQ5FB8
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
        </div>
      </div> */}
      <div className="chat-message-grp chat-encrypt">
        <ul>
          <li>
            <Link
              to="#"
              className="star-message-left"
              onClick={() => {
                if (data?.group_info.conversation_id != undefined) {
                  onshowStarredMsg(data?.group_info.conversation_id);
                }
              }}
            >
              <div className="stared-group">
                <span className="star-message">
                  {" "}
                  <i className="bx bxs-star" />
                </span>
                <h6>Starred Messages</h6>
              </div>
              <div className="count-group">
                <span>{data?.group_info.stared}</span>
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target={
                data?.group_info.mute == true
                  ? "#unmute-notification"
                  : "#mute-notification"
              }
              onClick={async (e) => {
                e.preventDefault();
                await fetchUserData();
              }}
            >
              <div className="stared-group">
                <span className="mute-message">
                  {" "}
                  <i className="bx bx-microphone-off" />
                </span>
                <h6>
                  {data?.group_info.mute == true ? "Unmute" : "Mute"}{" "}
                  Notifications
                </h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          {/* <li>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#disappearing-messages"
                      >
                        <div className="stared-group">
                          <span className="disapper-message">
                            {" "}
                            <i className="bx bx-time" />
                          </span>
                          <h6>Disappearing messages</h6>
                        </div>
                        <div className="count-group">
                          <i className="feather-chevron-right" />
                        </div>
                      </Link>
                    </li> */}
          <li onClick={() => setGroupSettingModal(true)}>
            <Link to="#" className="group-left-setting">
              <div className="stared-group">
                <span className="group-setting-msg">
                  {" "}
                  <i className="bx bx-cog" />
                </span>
                <h6>Group Setting</h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="participants-list">
        <div className="participants-list-group">
          <h6>
            {data?.group_info.members_count} Participants
            {/* <span className="parti-search">
              <i className="bx bx-search" />
            </span> */}
          </h6>
          <ul className="user-list mt-2">
            {data?.member_info.map((member) => (
              <li className="d-flex" key={"group-member-" + member.id}>
                <div>
                  <div className="avatar">
                    <ImageWithBaseURL
                      src={
                        member.img == "" || member.img == null
                          ? `${base_path}assets/img/avatar/profile.png`
                          : `${api_base_url}/${member.img}`
                      }
                      className="rounded-circle"
                      alt="image"
                    />
                  </div>
                </div>
                <div className="users-list-body d-flex justify-content-between">
                  <div>
                    <h5>{member.name}</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    {member.is_admin ? (
                      <Link to="#" className="p-0 m-0 admin-shade">
                        <span className="admin-profiles">Admin</span>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {data.group_info.is_admin == 1 &&
                      member.sid !== getUser()?.code && (
                        <div className="group-set-wrap d-flex align-items-center ms-1">
                          <span className="d-flex" data-bs-toggle="dropdown">
                            <i className="bx bx-dots-vertical-rounded" />
                          </span>
                          <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                            {member.is_admin ? (
                              <span
                                className="dropdown-item"
                                onClick={async () => {
                                  const res = await post(
                                    `/api/groups/${data.group_info.id}/members/${member.user_id}/remove-admin
`,
                                    {}
                                  );
                                  if (res.code == 200) {
                                    fetchChatData();
                                  } else {
                                    console.error("Error");
                                  }
                                }}
                              >
                                <span>
                                  <i className="bx bx-user-x" />
                                </span>
                                Remove Admin
                              </span>
                            ) : (
                              <span
                                className="dropdown-item"
                                onClick={async () => {
                                  const res = await post(
                                    `/api/groups/${data.group_info.id}/members/${member.user_id}/made-admin`,
                                    {}
                                  );
                                  if (res.code == 200) {
                                    fetchChatData();
                                  } else {
                                    console.error("Error");
                                  }
                                }}
                              >
                                <span>
                                  <i className="bx bx-user-check" />
                                </span>
                                Make as Admin
                              </span>
                            )}
                            <span
                              className="dropdown-item"
                              onClick={async () => {
                                const res = await deleteMethod(
                                  `/api/groups/${data.group_info.id}/members/${member.user_id}`
                                );
                                if (res.code == 200) {
                                  fetchChatData();
                                } else {
                                  console.error("Error");
                                }
                              }}
                            >
                              <span>
                                <i className="bx bx-user-minus" />
                              </span>
                              Remove from Group
                            </span>

                            {/* <span className="dropdown-item ">
                            <span>
                              <i className="bx bx-archive-in" />
                            </span>
                            Archive Chat{" "}
                          </span>
                          <span
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#mute-notification"
                          >
                            <span>
                              <i className="bx bx-volume-mute" />
                            </span>
                            Mute Notification
                          </span>
                          <span className="dropdown-item">
                            <span>
                              <i className="bx bx-log-out" />
                            </span>
                            Exit Group
                          </span>
                          <span className="dropdown-item">
                            <span>
                              <i className="bx bx-pin" />
                            </span>
                            Unpin Chat
                          </span>
                          <span className="dropdown-item">
                            <span>
                              <i className="bx bx-check-square" />
                            </span>
                            Mark as Unread
                          </span> */}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {/* <div className="text-left group-view-all">
            <Link to="#" className="load-more-btn">
              View All
            </Link>
          </div> */}
        </div>
      </div>
      <div className="chat-message-grp group-exits">
        <ul>
          <li>
            <Link
              to="#"
              data-bs-toggle=""
              data-bs-target=""
              onClick={() => setExitGroupModal(true)}
            >
              <div className="stared-group">
                <span className="exit-bg-message">
                  {" "}
                  <i className="bx bx-log-out" />
                </span>
                <h6>Exit Group</h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              data-bs-toggle=""
              data-bs-target=""
              onClick={() => {
                data &&
                  data.group_info.id &&
                  callReportModal("group", data.group_info.id);
              }}
            >
              <div className="stared-group">
                <span className="exit-bg-message">
                  {" "}
                  <i className="bx bx-dislike" />
                </span>
                <h6>Report Group</h6>
              </div>
              <div className="count-group">
                <i className="feather-chevron-right" />
              </div>
            </Link>
          </li>
        </ul>
      </div>

      <Modal
        show={groupSettingModal}
        onHide={() => {
          setGroupSettingModal(false);
        }}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable"
      >
        <div className="modal-content delete-model-head">
          <div className="modal-header">
            <h5 className="modal-title">Group Settings</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setGroupSettingModal(false);
              }}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="notify-check group-settings-notify">
              {/* Admin Only Edit Group */}
              <div className="form-check d-flex align-items-center justify-content-start ps-0">
                <label className="custom-check mt-0 mb-0">
                  <span className="remember-me">Admin Only Edit Group</span>
                  <input
                    type="checkbox"
                    name="adminOnlyEditGroup"
                    checked={
                      data?.group_info?.is_admin_only_edit_group == 1
                        ? true
                        : false
                    }
                    onChange={async (e) => {
                      const res = await post(
                        `/api/groups/${data?.group_info.id}/setting`,
                        {
                          status: e.target.checked ? 1 : 0,
                          type: "is_admin_only_edit_group",
                        }
                      );
                      if (res.code == 200) {
                        fetchChatData();
                        // setGroupSettingModal(false);
                      } else {
                        console.error("Error");
                      }
                    }}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {/* Admin Only Send Messages */}
              <div className="form-check d-flex align-items-center justify-content-start ps-0">
                <label className="custom-check mt-0 mb-0">
                  <span className="remember-me">Admin Only Send Messages</span>
                  <input
                    type="checkbox"
                    name="adminOnlySendMessages"
                    checked={
                      data?.group_info?.is_admin_only_send_msg == 1
                        ? true
                        : false
                    }
                    onChange={async (e) => {
                      const res = await post(
                        `/api/groups/${data?.group_info.id}/setting`,
                        {
                          status: e.target.checked ? 1 : 0,
                          type: "is_admin_only_send_msg",
                        }
                      );
                      if (res.code == 200) {
                        fetchChatData();
                        // setGroupSettingModal(false);
                      } else {
                        console.error("Error");
                      }
                    }}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            {/* <div className="mute-chat-btn delete-pop-btn justify-content-end">
              <a
                className="btn btn-secondary"
                onClick={() => {setGroupSettingModal(false)}}
                aria-label="Close"
              >
                <i className="feather-x me-1" />
                Cancel
              </a>
              <a
                className="btn btn-primary"
                // onClick={() => {}}
              >
                <i className="bx bx-save me-1" />
                Save Settings
              </a>
            </div> */}
          </div>
        </div>
      </Modal>

      <Modal
        show={exitGroupModal}
        onHide={() => {
          setExitGroupModal(false);
        }}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable"
      >
        <div className="modal-content delete-model-head">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Exit Group</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setExitGroupModal(false);
              }}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to exit this group?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setExitGroupModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={async () => {
                const res = await post(
                  `/api/groups/${data?.group_info.id}/exit`,
                  {}
                );
                if (res.code === 200) {
                  setExitGroupModal(false);
                  fetchChatData();
                  setNewKey(Math.random());
                } else {
                  console.error("Failed to exit the group.");
                }
              }}
            >
              Yes, Exit Group
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
