import React, { useState } from 'react'
import { all_routes } from '../../router/all_routes'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath'
// import PhoneInput from 'react-phone-number-input'
import { post } from '../../../connections/fetch_wrapper'
import { setToken, setUser } from '../../../helpers/helpers'
import { useNavigate } from "react-router-dom";


const Login = () => {
  const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const routes=all_routes;

    async function handleLogin(){
        const response = await post(`/api/login`,{
          'email':username,
          password
        });
        if (response.code == 200) {
          setToken(response.data['token']);
          setUser(response.data.image,response.data.name,response.data.code);
          console.log(response);
          navigate(routes.index);
          // {routes.index}
        } else { 
        console.log(response);
        }
    }

  return (
   <div className="container-fluid">
  <div className="login-wrapper">
    <header className="logo-header">
      <Link to={routes.index} className="logo-brand">
        <ImageWithBasePath src="./assets/img/login-logo.png" alt="Logo" className="img-fluid logo-dark" />
        <h5>DREAMSCHAT</h5>
      </Link>
    </header>
    <div className="login-inbox">
      <div className="log-auth">
        <div className="login-auth-wrap">
          <div className="login-content-head">
            <h3>Login</h3>
            <p>Hi Welcome Back</p>
          </div>
        </div>
        <form>
          <div className="form-group">
            <label className="form-label">Username <span>*</span></label>
            {/* <input className="form-control form-control-lg group_formcontrol" id="phone" name="phone" type="text" /> */}
            {/* <PhoneInput
              placeholder="Enter username"
              value={value}
              onChange={()=>{setValue}}
              className="form-control form-control-lg group_formcontrol"
              /> */}
              <div className="user-group">
              <input type="text" className="form-control pass-input" onChange={(e) => setUsername(e.target.value)} />
              </div>
          </div>
          <div className="form-group">
            <label className="form-label">Password <span>*</span></label>
            <div className="pass-group">
              <input type="password" className="form-control pass-input" onChange={(e) => setPassword(e.target.value)} />
              <span className="toggle-password fa-solid fa-eye" />
            </div>
          </div>
          <div className="form-group form-remember d-flex align-items-center justify-content-between">
            <div className="form-check d-flex align-items-center justify-content-start ps-0">
              <label className="custom-check mt-0 mb-0">
                <span className="remember-me">Remember Me</span>
                <input type="checkbox" name="remeber" />                                        
                <span className="checkmark" />
              </label>
            </div>
            {/* <span className="forget-pass">
              <Link to={routes.forgotpassword}>
                Forgot Password
              </Link>
            </span> */}
          </div>
          <button type='button'  className="btn btn-primary w-100 btn-size justify-content-center" onClick={handleLogin}>Login</button>
        </form>
      </div>
    </div>                
  </div>  
</div>
  )
}

export default Login