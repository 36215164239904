import React, { ChangeEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Content } from "../../interfaces/message";
import { api_base_url } from "../../environment";

interface Props {
  initialAttachment?: File[];
  initialAttachmentImages?: File[];
  initialText?: string | null;
  sendMsg: (
    text: string | null,
    attachment: File[],
    attachmentImages: File[]
  ) => void;
  replyMsg: Content | null;
  clearReplyMsg: () => void;
}

const ChatFooter: React.FC<Props> = ({
  initialAttachment,
  initialAttachmentImages,
  initialText,
  sendMsg,
  replyMsg,
  clearReplyMsg,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const [attachment, setAttachment] = useState<File[]>([]);
  const [attachmentImages, setAttachmentImages] = useState<File[]>([]);
  const [inputMsgValue, setInputMsgValue] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [viewFile, setViewFile] = useState<File | null>(null);
  const [viewFileID, setViewFileID] = useState<number | null>(null);
  const [viewFileType, setViewFileType] = useState<"Images" | "File" | null>(
    null
  );

  const handleMsgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMsgValue(event.target.value);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != undefined && files?.length > 0) {
      const filesArray = Array.from(files);
      setAttachment((prevFiles) => [...prevFiles, ...filesArray]);
      setViewFile(filesArray[0]);
      setViewFileType("File");
    }
  };

  const handleImageClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != undefined && files?.length > 0) {
      const filesArray = Array.from(files);
      setAttachmentImages((prevFiles) => [...prevFiles, ...filesArray]);
      setViewFile(filesArray[0]);
      setViewFileType("Images");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const ImagePreview = () => {
    if (viewFile == null) return null;
    return (
      <div className="mt-2">
        <div className="image-preview">
          <div>
            <div className="pt-2 pb-4">{viewFile.name}</div>
            {fileType(viewFile)}
            <div className="pt-2 pb-4">
              {Math.round(viewFile.size / 1024)} KB
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fileType = (file: File) => {
    const url = URL.createObjectURL(file);
    console.log("file.type", file.type);

    if (file.type.startsWith("image/")) {
      return <img src={url} alt="image" />;
    } else {
      // For other file types, just display a link
      return (
        <div>
          <i className="bx bxs-file"></i>
        </div>
      );
    }

    // else if (file.type === 'application/pdf') {
    // return <embed src={url} type="application/pdf" width="100%" height="600px" />;
    // }else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //     // For Excel files
    //     return <div><i className='bx bxs-spreadsheet' ></i></div>;
    // }else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    //     // For Excel files
    //     return <div><i className='bx bxs-file-doc'></i></div>;
    // }
  };

  function uploadMore() {
    if (viewFileType == "File") {
      handleClick();
    } else if (viewFileType == "Images") {
      handleImageClick();
    }
  }

  function removeFile(id: number) {
    if (viewFileType == "File") {
      setAttachment((prevImages) =>
        prevImages.filter((image, index) => index !== id)
      );
    } else if (viewFileType == "Images") {
      setAttachmentImages((prevImages) =>
        prevImages.filter((image, index) => index !== id)
      );
    }
  }

  const renderImagePreviews = () => {
    if (viewFileType == null) return null;

    const fileList: File[] =
      viewFileType == "Images" ? attachmentImages : attachment;

    const imagePreviews = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      imagePreviews.push(
        <button
          type="button"
          className={`file-box ${viewFileID == i && "file-box-active"}`}
          aria-label="image"
          onClick={() => {
            setViewFile(fileList[i]);
            setViewFileID(i);
          }}
        >
          <div
            className="clear-file-box"
            role="button"
            onClick={() => {
              removeFile(i);
            }}
          >
            <span data-icon="x-alt" className="">
              <svg
                viewBox="0 0 24 24"
                height={24}
                width={24}
                preserveAspectRatio="xMidYMid meet"
                className=""
                version="1.1"
                x="0px"
                y="0px"
                enableBackground="new 0 0 24 24"
              >
                <title>x-alt</title>
                <path
                  fill="currentColor"
                  d="M17.25,7.8L16.2,6.75l-4.2,4.2l-4.2-4.2L6.75,7.8l4.2,4.2l-4.2,4.2l1.05,1.05l4.2-4.2l4.2,4.2l1.05-1.05 l-4.2-4.2L17.25,7.8z"
                />
              </svg>
            </span>
          </div>
          <div className="file-box-bg">
            <div className="file-box-view">{fileType(file)}</div>
          </div>
        </button>
      );
    }

    imagePreviews.push(
      <button
        type="button"
        className={`file-box file-box-plus`}
        aria-label="image"
        onClick={uploadMore}
      >
        <div className="clear-file-box" role="button">
          <span data-icon="x-alt" className="">
            <svg
              viewBox="0 0 24 24"
              height={24}
              width={24}
              preserveAspectRatio="xMidYMid meet"
              className=""
              version="1.1"
              x="0px"
              y="0px"
              enableBackground="new 0 0 24 24"
            >
              <title>x-alt</title>
              <path
                fill="currentColor"
                d="M17.25,7.8L16.2,6.75l-4.2,4.2l-4.2-4.2L6.75,7.8l4.2,4.2l-4.2,4.2l1.05,1.05l4.2-4.2l4.2,4.2l1.05-1.05 l-4.2-4.2L17.25,7.8z"
              />
            </svg>
          </span>
        </div>
        <div className="file-box-bg">
          <div className="file-box-view">
            <div>
              <i className="bx bx-plus"></i>
            </div>
          </div>
        </div>
      </button>
    );
    return imagePreviews;
  };

  function sendMessage() {
    sendMsg(inputMsgValue, attachment, attachmentImages);
    clearAttachment();
    setInputMsgValue("");
  }

  function clearAttachment() {
    setAttachmentImages([]);
    setAttachment([]);
    setViewFile(null);
    setViewFileID(null);
    setViewFileType(null);
  }

  return (
    <div className="chat-footer">
      {viewFileType != null && (
        <div className="file-select-summery">
          <div className="contact-close_file">
            <Link
              to="#"
              className="close-replay"
              onClick={() => {
                clearAttachment();
              }}
            >
              <i className="bx bx-x" />
            </Link>
          </div>
          {viewFile == null ? (
            <></>
          ) : (
            <div className="image-preview">{ImagePreview()}</div>
          )}
          <div className="chats-file-select">
            <div className="file-list-show">
              <div className="d-flex ps-4 pe-4 ms-4 me-4 mt-4">
                <input
                  value={inputMsgValue}
                  onChange={handleMsgChange}
                  onKeyDown={handleKeyDown}
                  type="text"
                  className="form-control chat_form"
                  placeholder="Type your message here..."
                />
                <div className="form-buttons">
                  <button
                    className="btn send-btn"
                    type="button"
                    onClick={sendMessage}
                  >
                    <i className="bx bx-paper-plane" />
                  </button>
                </div>
              </div>
              <hr className="m-2" />
              <div className="d-flex justify-content-center">
                {renderImagePreviews()}
              </div>
            </div>
          </div>
        </div>
      )}

      <form>
        <div className="smile-foot">
          <div className="chat-action-btns">
            <div className="chat-action-col">
              <Link className="action-circle" to="#" data-bs-toggle="dropdown">
                <i className="bx bx-dots-vertical-rounded" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                />
                <input
                  type="file"
                  ref={imageInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  multiple
                  accept="image/*" // Accept only image files
                />
                <Link to="#" className="dropdown-item" onClick={handleClick}>
                  <span>
                    <i className="bx bx-file" />
                  </span>
                  Document
                </Link>
                {/* <Link to="#" className="dropdown-item" onClick={handleImageClick}>
                  <span>
                    <i className="bx bx-camera" />
                  </span>
                  Camera
                </Link> */}
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={handleImageClick}
                >
                  <span>
                    <i className="bx bx-image" />
                  </span>
                  Gallery
                </Link>
                {/* <Link to="#" className="dropdown-item">
                  <span>
                    <i className="bx bx-volume-full" />
                  </span>
                  Audio
                </Link> */}
                {/* <Link to="#" className="dropdown-item">
                  <span>
                    <i className="bx bx-map" />
                  </span>
                  Location
                </Link> */}
                {/* <Link to="#" className="dropdown-item">
                  <span>
                    <i className="bx bx-user-pin" />
                  </span>
                  Contact
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="smile-foot emoj-action-foot">
          <Link to="#" className="action-circle">
            <i
              className="bx bx-smile"
              onClick={() => setShowContent(!showContent)}
            />
          </Link>
          <div
            className="emoj-group-list-foot down-emoji-circle"
            style={{ display: showContent ? "block" : "none" }}
          >
            <ul>
              <li>
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/icon/emoj-icon-01.svg"
                    alt="Icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/icon/emoj-icon-02.svg"
                    alt="Icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/icon/emoj-icon-03.svg"
                    alt="Icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/icon/emoj-icon-04.svg"
                    alt="Icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/icon/emoj-icon-05.svg"
                    alt="Icon"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div> */}
        {/* <div className="smile-foot">
          <Link to="#" className="action-circle">
            <i className="bx bx-microphone-off" />
          </Link>
        </div> */}
        <div className="replay-forms-attachement">
          <div className="chats forward-chat-msg reply-div mb-2 d-none">
            <div className="contact-close_call text-end">
              <Link to="#" className="close-replay">
                <i className="bx bx-x" />
              </Link>
            </div>
            <div className="chat-avatar">
              <ImageWithBasePath
                src="assets/img/avatar/avatar-2.jpg"
                className="rounded-circle dreams_chat"
                alt="image"
              />
            </div>
            <div className="chat-content">
              <div className="chat-profile-name">
                <h6>
                  Mark Villiams<span>8:16 PM</span>
                </h6>
                <div className="chat-action-btns ms-2">
                  <div className="chat-action-col">
                    <Link className="#" to="#" data-bs-toggle="dropdown">
                      <i className="bx bx-dots-horizontal-rounded" />
                    </Link>
                    <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                      <Link to="#" className="dropdown-item message-info-left">
                        <span>
                          <i className="bx bx-info-circle" />
                        </span>
                        Message Info{" "}
                      </Link>
                      <Link to="#" className="dropdown-item reply-button">
                        <span>
                          <i className="bx bx-share" />
                        </span>
                        Reply
                      </Link>
                      <Link to="#" className="dropdown-item">
                        <span>
                          <i className="bx bx-smile" />
                        </span>
                        React
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#forward-message"
                      >
                        <span>
                          <i className="bx bx-reply" />
                        </span>
                        Forward
                      </Link>
                      <Link to="#" className="dropdown-item">
                        <span>
                          <i className="bx bx-star" />
                        </span>
                        Star Message
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#report-user"
                      >
                        <span>
                          <i className="bx bx-dislike" />
                        </span>
                        Report
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#delete-message"
                      >
                        <span>
                          <i className="bx bx-trash" />
                        </span>
                        Delete
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="message-content reply-content"></div>
            </div>
          </div>
          <div className="replay-forms">
            {replyMsg != null && (
              <div className="chats forward-chat-msg reply-div d-flex">
                <div className="contact-close_call text-end">
                  <a href="#" className="close-replay" onClick={clearReplyMsg}>
                    <i className="bx bx-x"></i>
                  </a>
                </div>
                <div className="chat-content">
                  <div className="chat-profile-name">
                    <h6>{replyMsg.sender}</h6>
                  </div>
                  {replyMsg.file_type == null ? (
                    <></>
                  ) : replyMsg.file_type == "image" ? (
                    <>
                      <i className="feather-image ms-1 me-1"></i>Image
                    </>
                  ) : (
                    <>
                      <i className="feather-file-text me-1"></i>File
                    </>
                  )}
                  <div className="message-content reply-content">
                    {replyMsg.content}
                  </div>
                </div>
              </div>
            )}
            <input
              value={inputMsgValue}
              onChange={handleMsgChange}
              onKeyDown={handleKeyDown}
              type="text"
              className="form-control chat_form"
              placeholder="Type your message here..."
            />
          </div>
        </div>
        <div className="form-buttons">
          <button className="btn send-btn" type="button" onClick={sendMessage}>
            <i className="bx bx-paper-plane" />
          </button>
        </div>
      </form>
    </div>
  );
};
export default ChatFooter;
