import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Scrollbars from "react-custom-scrollbars-2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeModals from "../../core/data/modals/homeModals";
import Sidebar from "../../core/common/sidebar";
import { all_routes } from "../router/all_routes";
import { ChatTitle, ChatTitle2 } from "../../core/common/chatTitle";
import { get, post } from "../../connections/fetch_wrapper";
import Chat from "./chat";
import { ChannelProvider, useChannel, usePresence } from "ably/react";
import { getUser } from "../../helpers/helpers";
import {
  ChatData,
  Content,
  Conversation,
  ConversationReadCount,
  MsgResponse,
  ViewChat,
} from "../../interfaces/message";
import ConversationsList from "./conversations_list";
import ContactsList from "./contacts_list";
import GroupList from "./groups_list";
import NewGroup from "./new-group";
import ConversationInfo from "./conversation_info";
import MessageInfo from "./message-info";
import ForwardMessage from "./forwardMessage";
import StarredMessages from "./starred_messages";
import SkeletonChatCard from "./SkeletonChatCard";
import NewChat from "./new-chat";

const loginUser = getUser();

const index = () => {
  return (
    <ChannelProvider channelName="update.listener">
      <ChannelProvider channelName="private.chat">
        <ConversationPage />
      </ChannelProvider>
    </ChannelProvider>
  );
};

export default index;

export const ConversationPage = () => {
  const routes = all_routes;
  const [currentTab, setCurrentTab] = useState<
    "content" | "group" | "chat" | "setting"
  >("chat");
  const [showGroupCreate, setShowGroupCreate] = useState(false);
  const [showChatCreate, setShowChatCreate] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  const [conversations, setConversation] = useState<Conversation[]>([]);
  const [conversationReadCount, setConversationReadCount] = useState<
    ConversationReadCount[]
  >([]);
  const [chatData, setChatData] = useState<ChatData>();
  const [receivedMsg, setReceivedMsg] = useState<Content | null>(null);
  const [forwardMsg, setForwardMsg] = useState<Content | null>(null);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [isSmallChatOptions, setIsSmallChatOptions] = useState(false);
  const [isShowRightSidebar, setIsShowRightSidebar] = useState(false);
  const [isShowMsgRightSidebar, setIsShowMsgRightSidebar] = useState(false);
  const [messageDetails, setMessageDetails] = useState<Content | null>(null);
  const [showForward, setShowForward] = useState(false);
  const [deleverdChat, setDeleverdChat] = useState<ChatData | null>(null);
  const [starredMessagesConversationId, setStarredMessagesConversationId] =
    useState<number | null>(null);
  const [skeleton, setSkeleton] = useState(false);
  const [key, setKey] = useState(1);
  const [newKey, setNewKey] = useState(1);
  const [fetchTrigger, setFetchTrigger] = useState(1);
  const [conversationId, setConversationId] = useState<number | null>(null);
  const [isInGroup, setIsInGroup] = useState(false);

  const contactInfoIconClick = () => {
    if (isInGroup) {
      setIsSmallChatOptions(!isSmallChatOptions);
      setIsShowRightSidebar(!isSmallChatOptions);
      setIsShowMsgRightSidebar(false);
    }
  };

  const msgInfoIconClick = (contact: Content) => {
    setIsSmallChatOptions(true);
    setIsShowMsgRightSidebar(true);
    setIsShowRightSidebar(false);
    setMessageDetails(contact);
  };

  useEffect(() => {
    setIsShowRightSidebar(false);
  }, [chatData]);

  useEffect(() => {
    fetchReadMsgCount();
    deleverdMsgUpdate();
  }, [fetchTrigger]);

  useEffect(() => {
    const fetchData = async () => {
      setSkeleton(true);

      if (currentTab === "chat") {
        await fetchChatData();
      } else if (currentTab === "content") {
        console.log("");
      } else if (currentTab === "group") {
        console.log("");
        setConversation([]);
        await fetchChatData();
      }

      setSkeleton(false);
    };

    fetchData();
  }, [currentTab, newKey]);
  const { channel } = useChannel(
    "private.chat",
    loginUser.code,
    async (message) => {
      updateConversations(message.data);
      if (
        chatData?.code ==
          (chatData?.type == "GROUP" ? message.data.gsid : message.data.sid) &&
        chatData?.type == message.data.conversation_type
      ) {
        messageListUpdate(message.data);
      }
    }
  );

  const { channeld } = useChannel(
    "update.listener",
    loginUser.code,
    (message) => {
      if (message.data.type == "deleverd_msg") {
        updateWsConversations(message.data);
      }
      console.log(message);
    }
  );

  function updateWsConversations(data: any) {
    const updateConversations: Conversation[] = [...conversations]; // Create a copy of the conversations array
    const index = updateConversations.findIndex(
      (item) => item.sid === data.sid
    );
    console.log(index);

    if (index >= 0) {
      updateConversations[index].delivered_at = data.date;
      setConversation(updateConversations);
      setDeleverdChat({
        code: updateConversations[index].sid,
        type: "INDIVIDUAL",
        deleverd_at: data.date,
      });
    }
  }

  function deleverdMsgUpdate() {
    get(`/api/conversations/delivered`);
  }

  function messageListUpdate(data: MsgResponse) {
    const newContent: Content = {
      id: data.id,
      content: data.content,
      status: "",
      sent_at: data.sent_at,
      sender: data.sender ?? "",
      img: data.sender_image ?? "",
      type: data.type,
      attachments: data.attachments,
      file_type: data.file_type,
      inital: false,
    };
    setReceivedMsg(newContent);
  }

  function newGroupResponse(message: MsgResponse) {
    updateConversations(message);
    viewChat("GROUP", message.gsid);
  }

  function updateConversations(message: MsgResponse) {
    const updateConversations: Conversation[] = [...conversations]; // Create a copy of the conversations array

    const index = updateConversations.findIndex(
      (item) => item.id === message.conversation_id
    );

    if (index >= 0) {
      updateConversations[index].content = message.content;
      updateConversations[index].sent_at = message.sent_at;
      updateConversations[index].sender = message.sender;
      updateConversations[index].type = message.type;
      updateConversations[index].file_type = message.file_type;
      updateConversations[index].delivered_at = null;
      updateConversations[index].seen_at = null;

      // Move the conversation to the beginning of the array
      const [removedConversation] = updateConversations.splice(index, 1);
      updateConversations.unshift(removedConversation);
    } else {
      const newConversation: Conversation = {
        id: message.conversation_id,
        name: message.name,
        img: message.img,
        conversation_type: message.conversation_type,
        is_pinned: false,
        is_blocked: false,
        gsid: message.gsid,
        sid: message.sid,
        content: message.content,
        sent_at: message.sent_at,
        delivered_at: null,
        seen_at: null,
        status: "DELIVERED",
        is_now_online: true,
        type: message.type,
        file_type: message.file_type,
        sender: message.conversation_sender,
        mute: false,
        blocked_by_me: false,
        blocked_by_you: false,
        is_sent: true,
        is_deleverd: false,
        is_seen: false,
      };
      updateConversations.unshift(newConversation); // If conversation doesn't exist, add it to the array
    }

    setConversation(updateConversations);
    if (conversationId && conversationId !== message.conversation_id) {
      setFetchTrigger(Math.random());
    }
    // setNewKey(Math.random());
  }

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 992);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function fetchChatData() {
    const response = await get(
      `/api/conversations?type=${currentTab == "group" ? "group" : "all"}`
    );
    if (response.code == 200) {
      console.log(response.data);
      setConversation(response.data);
    } else {
      console.log(response);
    }
  }

  async function fetchReadMsgCount() {
    const response = await get("/api/conversations/un-read-msgs");
    if (response.code == 200) {
      console.log(response.data);
      setConversationReadCount(response.data);
    } else {
      console.log(response);
    }
  }

  function gotoChat(conversation: Conversation) {
    if (conversation.conversation_type == "INDIVIDUAL") {
      viewChat("INDIVIDUAL", conversation.sid);
      // localStorage.setItem('_tsdrtf', JSON.stringify({'type':'INDIVIDUAL','code':conversation.sid}));
    } else if (conversation.conversation_type == "GROUP") {
      viewChat("GROUP", conversation.gsid);
      // localStorage.setItem('_tsdrtf', JSON.stringify({'type':'GROUP','code':conversation.gsid}));
    }
    setKey(key + 1);
  }

  function viewChat(type: "INDIVIDUAL" | "GROUP", code: string) {
    setChatData({ type: type, code: code });
  }

  function onUpdated(id: number, type: "delete" | "mute" | "block") {
    const updateConversations: Conversation[] = [...conversations]; // Create a copy of the conversations array
    const index = updateConversations.findIndex((item) => item.id === id);
    if (index === -1) return;

    if (type == "delete") {
      if (
        (chatData?.code == updateConversations[index].sid &&
          chatData?.type == "INDIVIDUAL") ||
        (chatData?.code == updateConversations[index].gsid &&
          chatData?.type == "GROUP")
      ) {
        setChatData(undefined);
      }
      updateConversations.splice(index, 1); // Remove 1 element at the found index
      setConversation(updateConversations);
    } else if (type == "mute") {
      updateConversations[index].mute = !updateConversations[index].mute;
      setConversation(updateConversations);
    } else if (type == "block") {
      updateConversations[index].blocked_by_me =
        !updateConversations[index].blocked_by_me;
      setConversation(updateConversations);
    }
    setKey(key + 1);
  }

  function handleCloseCreateGroup() {
    setShowGroupCreate(false);
  }

  function handleCloseForwardMsg() {
    setShowForward(false);
  }

  function forwardInfoIconClick(content: Content) {
    setShowForward(true);
    setForwardMsg(content);
  }

  function onCloseConversationInfoSideBar() {
    setIsShowRightSidebar(false);
    setIsSmallChatOptions(false);
  }

  function onshowStarredMsg(id: number) {
    onCloseConversationInfoSideBar();
    setTimeout(() => {
      setStarredMessagesConversationId(id);
    }, 400);
  }

  const matkAsRead = async (id: number) => {
    const res = await post(`/api/messages/read/${id}`, {});
    if (res.code == 200) {
      setFetchTrigger(Math.random());
    } else {
      console.log("error");
    }
  };

  return (
    <>
      {/* content */}
      <div className="content main_content">
        {/* Left Sidebar Menu */}
        <Sidebar
          onselect={(type) => {
            setCurrentTab(type);
          }}
        />
        {/* /Left Sidebar Menu */}
        {/* sidebar group */}
        <div className="sidebar-group left-sidebar chat_sidebar">
          {/* Chats sidebar */}
          <div
            id="chats"
            className="left-sidebar-wrap sidebar active slimscroll"
          >
            <Scrollbars className={isSmallScreen ? "mob-scrn" : ""}>
              <div>
                <div className="slimscroll">
                  <ChatTitle2
                    openNewChat={() => {
                      setShowGroupCreate(true);
                    }}
                    openNew={() => {
                      setShowChatCreate(true);
                    }}
                  />
                </div>
                {/* /Top Online Contacts */}
                {skeleton ? (
                  <SkeletonChatCard />
                ) : currentTab == "chat" ? (
                  <ConversationsList
                    matkAsRead={matkAsRead}
                    conversations={conversations}
                    conversationReadCount={conversationReadCount}
                    selectedConversation={(conversationVal) => {
                      setSelectedConversation(conversationVal);
                    }}
                    gotoChat={(conversationVal) => {
                      gotoChat(conversationVal);
                    }}
                  />
                ) : currentTab == "content" ? (
                  <ContactsList
                    gotoChat={(conversationVal) => {
                      gotoChat(conversationVal);
                    }}
                  />
                ) : currentTab == "group" ? (
                  <GroupList
                    conversations={conversations}
                    conversationReadCount={conversationReadCount}
                    selectedConversation={(conversationVal) => {
                      setSelectedConversation(conversationVal);
                    }}
                    gotoChat={(conversationVal) => {
                      gotoChat(conversationVal);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Scrollbars>
          </div>
          {/* / Chats sidebar */}
        </div>
        {/* /Sidebar group */}
        {/* Chat */}
        {chatData ? (
          <Chat
            conversationId={conversationId}
            setConversationId={setConversationId}
            setFetchTrigger={setFetchTrigger}
            newKey={newKey}
            chatData={chatData}
            sendMsg={updateConversations}
            deleverdChat={deleverdChat}
            receivedMsg={receivedMsg}
            contactInfoIconClick={contactInfoIconClick}
            msgInfoIconClick={msgInfoIconClick}
            isSmallChatOptions={isSmallChatOptions}
            forwardInfoIconClick={forwardInfoIconClick}
            isInGroup={isInGroup}
          />
        ) : (
          <div className="chat status-middle-bar d-flex align-items-center justify-content-center">
            <div className="status-right">
              <div className="empty-chat-img">
                <ImageWithBasePath
                  src="assets/img/empty-img-01.png"
                  alt="Image"
                />
              </div>
              <div className="empty-dark-img">
                <ImageWithBasePath
                  src="assets/img/empty-img-dark.png"
                  alt="Image"
                />
              </div>
              <div className="select-message-box">
                <h4>Select Message</h4>
                <p>
                  To see your existing conversation or share a link below to
                  start new
                </p>
                <Link
                  to="#"
                  className="btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#new-chat"
                  onClick={() => setShowChatCreate(true)}
                >
                  <i className="bx bx-plus me-1" />
                  Add New Message
                </Link>
              </div>
            </div>
          </div>
        )}
        {/* /Chat */}

        <HomeModals conversation={selectedConversation} onUpdated={onUpdated} />
        <NewGroup
          onUpdated={(msg) => {
            newGroupResponse(msg);
          }}
          show={showGroupCreate}
          handleClose={handleCloseCreateGroup}
        />
        <NewChat
          gotoChat={(conversationVal) => {
            gotoChat(conversationVal);
          }}
          show={showChatCreate}
          setShowChatCreate={setShowChatCreate}
          handleClose={() => setShowChatCreate(false)}
        />
        <ConversationInfo
          key={key}
          setNewKey={setNewKey}
          setSelectedConversation={setSelectedConversation}
          onshowStarredMsg={onshowStarredMsg}
          isShowRightSidebar={isShowRightSidebar}
          toUserSid={
            chatData?.type == "INDIVIDUAL" && chatData?.code != null
              ? chatData?.code
              : undefined
          }
          groupSid={
            chatData?.type == "GROUP" && chatData?.code != null
              ? chatData?.code
              : undefined
          }
          onCloseSideBar={onCloseConversationInfoSideBar}
          setIsInGroup={setIsInGroup}
        />
        <MessageInfo
          isShowRightSidebar={isShowMsgRightSidebar}
          messageDetails={messageDetails}
          onCloseSideBar={() => {
            setIsShowMsgRightSidebar(false);
            setIsSmallChatOptions(false);
            setMessageDetails(null);
          }}
        />
        <ForwardMessage
          show={showForward}
          handleClose={handleCloseForwardMsg}
          msg={forwardMsg}
        />
        {starredMessagesConversationId != null ? (
          <StarredMessages
            conversationId={starredMessagesConversationId}
            isShowRightSidebar={starredMessagesConversationId != null}
            onCloseSideBar={() => {
              setStarredMessagesConversationId(null);
              contactInfoIconClick();
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {/* /Content */}
    </>
  );
};
