import React from "react";

const SkeletonChatCard: React.FC = () => {
    return (
        <div className="sidebar-body chat-body" id="chatsidebar">
            {/* Skeleton for Pinned Chat Title */}
            <div className="d-flex justify-content-between align-items-center ps-0 pe-0 mb-3">
                <div className="fav-title pin-chat">
                    <h6 className="placeholder-glow">
                        <span className="placeholder col-6"></span>
                    </h6>
                </div>
            </div>

            {/* Skeleton for Chat List */}
            <ul className="user-list space-chat">
                {[...Array(5)].map((_, index) => (
                    <li
                        className="user-list-item chat-user-list mb-3"
                        key={`skeleton-${index}`}
                    >
                        <div className="d-flex align-items-center">
                            {/* Skeleton Avatar */}
                            <div className="avatar placeholder-glow me-2">
                                <div
                                    className="placeholder rounded-circle"
                                    style={{ width: "50px", height: "50px" }}
                                ></div>
                            </div>
                            {/* Skeleton Text Section */}
                            <div className="users-list-body w-100 placeholder-glow">
                                <div className="mb-2">
                                    <span className="placeholder col-7"></span>
                                </div>
                                <div>
                                    <span className="placeholder col-4"></span>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Skeleton for Recent Chat Title */}
            <div className="d-flex justify-content-between align-items-center ps-0 pe-0 mt-4">
                <div className="fav-title pin-chat">
                    <h6 className="placeholder-glow">
                        <span className="placeholder col-6"></span>
                    </h6>
                </div>
            </div>

            {/* Skeleton for Recent Chat List */}
            <ul className="user-list">
                {[...Array(5)].map((_, index) => (
                    <li
                        className="user-list-item chat-user-list mb-3"
                        key={`skeleton-recent-${index}`}
                    >
                        <div className="d-flex align-items-center">
                            {/* Skeleton Avatar */}
                            <div className="avatar placeholder-glow me-2">
                                <div
                                    className="placeholder rounded-circle"
                                    style={{ width: "50px", height: "50px" }}
                                ></div>
                            </div>
                            {/* Skeleton Text Section */}
                            <div className="users-list-body w-100 placeholder-glow">
                                <div className="mb-2">
                                    <span className="placeholder col-7"></span>
                                </div>
                                <div>
                                    <span className="placeholder col-4"></span>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SkeletonChatCard;
