import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { get, postFormData } from "../../connections/fetch_wrapper";
import { Contact, Conversation, MsgResponse } from "../../interfaces/message";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SkeletonChatCard from "./SkeletonChatCard";
import { showAlert } from "../../core/common/alertUtil";

interface Props {
  show: boolean;
  setShowChatCreate: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  gotoChat: (conversation: Conversation) => void;
}

const NewChat: React.FC<Props> = ({
  show,
  setShowChatCreate,
  handleClose,
  gotoChat,
}) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedContact, setSelectedContact] = useState<Contact>(); // State for selected contacts
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show]);

  function groupContactsByAlphabet(contacts: Contact[]) {
    return contacts.reduce((acc: any, contact) => {
      const letter = contact.name[0].toUpperCase();
      if (!acc[letter]) acc[letter] = [];
      acc[letter].push(contact);
      return acc;
    }, {});
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkeleton(true);
    const value = event.target.value.toLowerCase();

    const filtered = contacts.filter(
      (contact) =>
        contact.name.toLowerCase().includes(value) ||
        contact.email.toLowerCase().includes(value)
    );

    setFilteredContacts(filtered);
    setTimeout(() => {
      setSkeleton(false);
    }, 500);
  };

  function goto(filteredContact: Contact) {
    const conversation: Conversation = {
      conversation_type: "INDIVIDUAL",
      sid: filteredContact.code,
      id: -1,
      name: "",
      sender: null,
      img: filteredContact.image,
      is_pinned: false,
      is_blocked: false,
      gsid: "",
      content: "",
      sent_at: "",
      status: "",
      is_now_online: false,
      type: null,
      file_type: null,
      blocked_by_you: filteredContact.blocked_by_me,
      blocked_by_me: filteredContact.blocked_by_you,
      mute: filteredContact.is_muted,
    };
    console.log(conversation);

    gotoChat(conversation);
    setShowChatCreate(false);
  }

  async function fetchData() {
    setSkeleton(true);
    const response = await get(`/api/users?start=${start}&pagePer=${pageSize}`);
    if (response.code == 200) {
      console.log(response);
      setContacts(response.data);
      setFilteredContacts(response.data);
    } else {
      console.log(response);
    }
    setSkeleton(false);
  }

  function handleCloseModal() {
    setContacts([]);
    setFilteredContacts([]);
    setStart(0);
    setSelectedContact(undefined);
    handleClose();
  }

  interface GroupedContacts {
    [letter: string]: Contact[];
  }

  const groupedContacts: GroupedContacts =
    groupContactsByAlphabet(filteredContacts);

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      dialogClassName="modal-dialog-centered modal-dialog-scrollable"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">New Chat</h5>
          <button
            type="button"
            className="close"
            onClick={handleCloseModal}
            aria-label="Close"
          >
            <span className="material-icons">close</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="user-block-group mb-4">
            <div className="search_chat has-search">
              <span className="fas fa-search form-control-feedback" />
              <input
                className="form-control chat_input"
                id="search-contacted"
                type="text"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
            <h5>Contacts</h5>
            {skeleton ? (
              <SkeletonChatCard />
            ) : filteredContacts.length > 0 ? (
              Object.entries(groupedContacts).map(([letter, contacts]) => (
                <div key={letter} className="sidebar">
                  <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                    <div className="fav-title contact-title">
                      <h6>{letter}</h6>
                    </div>
                  </div>
                  <ul className="user-list">
                    {contacts.map((contact, index) => (
                      <li key={contact.id || index} className="user-list-item">
                        <div className="list-user-blk">
                          <div className="avatar">
                            <ImageWithBaseURL
                              src={
                                contact.image == "" || contact.image == null
                                  ? `${base_path}assets/img/avatar/profile.jpg`
                                  : `${api_base_url}/${contact.image}`
                              }
                              className="rounded-circle"
                              alt="image"
                            />
                          </div>
                          <div className="users-list-body">
                            <div>
                              <h5>{contact.name}</h5>
                              <p>{contact.email}</p>
                            </div>
                          </div>
                          <div className="notify-check parti-notify-radio">
                            <div className="form-check d-flex align-items-center justify-content-start ps-0">
                              <label className="group-type-radio">
                                <input
                                  type="radio"
                                  name="radio"
                                  onChange={() => setSelectedContact(contact)}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>No contacts available</p>
            )}
          </div>
        </div>

        <div className="modal-footer">
          <div className="mute-chat-btn">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              <i className="feather-x me-1" /> Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                selectedContact && goto(selectedContact);
              }}
            >
              <ImageWithBasePath
                src="assets/img/icon/send.svg"
                className="me-1"
                alt="image"
              />
              Chat
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewChat;
