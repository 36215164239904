import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import OverlayTrigger, { OverlayInjectedProps } from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ImageWithBasePath from '../data/img/ImageWithBasePath';
import { all_routes } from '../../feature-module/router/all_routes';
import {useDispatch, useSelector} from 'react-redux';
import { setDarkMode } from '../data/redux/commonSlice';

const Sidebar: React.FC<{ onselect: (type: 'content' | 'group' | 'chat' | 'setting') => void;}> = ({ onselect }) => {

  const location =useLocation();
  const [currentTab, setCurrentTab]=useState<'content' | 'group' | 'chat' | 'setting'>('chat');

  // console.log(location.pathname,"pathname")
  
    const renderTooltip: React.FC<OverlayInjectedProps> = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Chat
        </Tooltip>
      );

      const routes = all_routes;

      const dispatch = useDispatch();
      const darkMode = useSelector(state => state.darkMode);

      useEffect(() => {
        console.log('hi');
        onselect(currentTab);
      }, [currentTab]);


  return (
    <div className="sidebar-menu">
          <div className="logo-col">
            <Link to={routes.index}>
              <ImageWithBasePath src="assets/img/logo-resize.png" alt="Logo" />
            </Link>
          </div>
          <div className="menus-col">
            <div className="chat-menus">
              <ul>
                <li>
                 
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Link onClick={() => { setCurrentTab('chat'); } } to={''} className={`chat-unread ${currentTab=='chat' ? " active":" "}`}>
 
                      <i className="bx bx-message-square-dots" />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <Link
                // to={routes.group}
                onClick={() => { setCurrentTab('group'); } }
                className={`chat-unread ${currentTab == 'group' ? " active" : " "}`}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title=""
                data-bs-original-title="Group" to={''}                  >
                    <i className="bx bx-group" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={''}
                    onClick={() => { setCurrentTab('content'); } }
                    className={`chat-unread ${currentTab=='content' ? " active":" "}`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title=""
                    data-bs-original-title="Contact"
                  >
                    <i className="bx bx-user-pin" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={''}
                    onClick={() => { setCurrentTab('setting'); } }
                    className={`chat-unread ${currentTab=='setting' ? " active":" "}`}                    
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title=""
                    data-bs-original-title="Settings"
                  >
                    <i className="bx bx-cog" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="bottom-menus">
              <ul>
                <li>
                  <Link
                    to="#"
                    id="dark-mode-toggle"
                    className="dark-mode-toggle active"
                    onClick={()=> dispatch(setDarkMode(!darkMode))}
                  >
                    <i className={darkMode ? "bx bx-sun" : "bx bx-moon"} />
                  </Link>
                  <Link
                    to="#"
                    id="light-mode-toggle"
                    className="dark-mode-toggle"
                  >
                    <i className="bx bx-sun" />
                  </Link>
                </li>
                <li>
                  <div className="avatar avatar-online">
                    <Link
                      to="#"
                      className="chat-profile-icon"
                      data-bs-toggle="dropdown"
                    >
                      <ImageWithBasePath
                        src="assets/img/avatar/avatar-2.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                      <Link to={routes.settings} className="dropdown-item">
                        <span>
                          <i className="bx bx-cog" />
                        </span>
                        Settings
                      </Link>
                      <Link to={routes.emailLogin} className="dropdown-item">
                        <span>
                          <i className="bx bx-log-out" />
                        </span>
                        Logout{" "}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
  )
}

export default Sidebar;