import React, { useState } from "react";

import { Link } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import { Conversation, ConversationReadCount } from "../../interfaces/message";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import TimeAgo from "react-timeago";

interface Props {
  conversations: Conversation[];
  conversationReadCount: ConversationReadCount[];
  gotoChat: (conversation: Conversation) => void;
  selectedConversation: (conversation: Conversation) => void;
}

const GroupList: React.FC<Props> = ({
  conversations,
  gotoChat,
  selectedConversation,
  conversationReadCount,
}) => {
  const [activeId, setActiveId] = useState<number | null>(null);
  return (
    <div className="sidebar-body chat-body" id="chatsidebar">
      {/* Left Chat Title */}
      <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
        <div className="fav-title pin-chat">
          <h6>Recent Group</h6>
        </div>
      </div>
      {/* /Left Chat Title */}
      <ul className="user-list">
        {conversations
          .filter((conversation) => conversation.is_pinned == false)
          .map((filteredConversation) => (
            <li
              className="user-list-item chat-user-list"
              key={filteredConversation.id}
            >
              <Link
                to="#"
                onClick={() => {
                  gotoChat(filteredConversation);
                  setActiveId(filteredConversation.id);
                }}
                className={
                  activeId == filteredConversation.id ? "status-active" : ""
                }
              >
                <div className="avatar">
                  <ImageWithBaseURL
                    src={
                      filteredConversation.img == "" ||
                      filteredConversation.img == null
                        ? `${base_path}assets/img/avatar/profile.jpg`
                        : `${api_base_url}/${filteredConversation.img}`
                    }
                    className="rounded-circle"
                    alt="image"
                  />
                </div>
                <div className="users-list-body">
                  <div>
                    <h5>{filteredConversation.name}</h5>
                    <p>
                      {filteredConversation.sender != null &&
                        filteredConversation.sender + " : "}
                      {/* <i className="feather-video ms-1 me-1" /> */}
                      {filteredConversation.content}
                    </p>
                  </div>
                  <div className="last-chat-time">
                    <small className="text-muted">
                      <TimeAgo date={filteredConversation.sent_at} />
                    </small>
                    <div className="chat-pin">
                      {conversationReadCount.find(
                        (item) =>
                          item.conversation_id === filteredConversation.id
                      ) ? (
                        <span className="count-message">
                          {
                            conversationReadCount.find(
                              (item) =>
                                item.conversation_id === filteredConversation.id
                            )?.unread_msg
                          }
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
              <div className="chat-hover"
              onClick={() => {
                selectedConversation(filteredConversation);
              }}>
                <div className="chat-action-col">
                  <span className="d-flex" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded" />
                  </span>
                  <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                    <span className="dropdown-item ">
                      <span>
                        <i className="bx bx-archive-in" />
                      </span>
                      Archive Group{" "}
                    </span>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target={`#${!filteredConversation.mute ? "mute-notification" : "unmute-notification"}`}
                    >
                      <span>
                        <i className={`bx bx-volume-${!filteredConversation.mute ? "mute" : "full"}`} />
                      </span>
                      {!filteredConversation.mute
                        ? "Mute Notification"
                        : "Unmute Notification"}
                    </span>
                    <span className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#change-chat">
                      <span>
                        <i className="bx bx-log-out" />
                      </span>
                      Delete Group
                    </span>
                    <span className="dropdown-item">
                      <span>
                        <i className="bx bx-pin" />
                      </span>
                      Pin Group
                    </span>
                    <span className="dropdown-item">
                      <span>
                        <i className="bx bx-check-square" />
                      </span>
                      Mark as Unread
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default GroupList;
